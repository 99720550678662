import { ComponentElementCondition, ContainerElement } from '../types/common/item-definition-types';
import { ItemValueKey } from './item-value-key';
import * as moment from 'moment-timezone';
import { ConfirmationDisplayType } from '../enums/confirmation/confirmation-display-type';
import { ComponentDescriptionType } from '../enums/common/component-description-type';
import { ValueOriginType } from '../enums/common/value-origin-type';
import { ComponentType } from '../enums/common/component-type';
import { DetailConditionOperator } from '../enums/common/detail-condition-operator';
import { FixedValueKey } from '../types/common/fixed-value-key';
import { ConditionsResultJudgeRule } from '../enums/common/conditions-result-judge-rule';
import { BasicItemKeys } from '../enums/item/basic-item-keys';

export const dateDisplayFormat = 'YYYY年MM月DD日(ddd)';

const useElectronicCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.HasElectricContract,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      }
    ]
  }
];
const useGasCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.HasElectricContract,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      },
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.UseGasPlanSelection,
        operator: DetailConditionOperator.NotEqual,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: '1'
          }
        ]
      },
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.SumamoruProjectType,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: 'CDE'
          }
        ]
      }
    ]
  }
];
const notUseGasCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.UseGasPlanSelection,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: '1'
          }
        ]
      }
    ]
  }
];

const notUseBasicGasPlan: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.GasBasePlanSelection,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: '1'
          }
        ]
      }
    ]
  }
];

const postCodeList = [
  '120-0012',
  '121-0012',
  '120-0015',
  '120-0005',
  '121-0823',
  '121-0807',
  '121-0836',
  '121-0834',
  '121-0816',
  '123-0851',
  '123-0873',
  '120-0001',
  '123-0844',
  '120-0046',
  '123-0861',
  '121-0055',
  '121-0056',
  '123-0842',
  '120-0013',
  '123-0872',
  '121-0833',
  '121-0832',
  '121-0053',
  '123-0862',
  '123-0864',
  '121-0815',
  '123-0865',
  '121-0051',
  '121-0057',
  '123-0852',
  '120-0034',
  '120-0023',
  '120-0026',
  '120-0025',
  '120-0031',
  '120-0037',
  '120-0033',
  '120-0045',
  '120-0024',
  '120-0042',
  '120-0035',
  '120-0036',
  '120-0038',
  '120-0044',
  '120-0043',
  '120-0041',
  '120-0032',
  '121-0813',
  '121-0054',
  '120-0011',
  '121-0011',
  '123-0871',
  '120-0003',
  '121-0831',
  '121-0837',
  '121-0835',
  '120-0002',
  '120-0014',
  '123-0841',
  '123-0843',
  '123-0845',
  '121-0824',
  '121-0825',
  '121-0074',
  '121-0822',
  '121-0812',
  '121-0061',
  '120-0004',
  '121-0801',
  '121-0063',
  '121-0071',
  '121-0075',
  '120-0021',
  '121-0076',
  '121-0064',
  '121-0072',
  '123-0874',
  '121-0062',
  '120-0047',
  '121-0052',
  '123-0853',
  '123-0854',
  '123-0855',
  '123-0856',
  '123-0857',
  '123-0863',
  '120-0006',
  '120-0022',
  '121-0814',
  '121-0073',
  '120-0000',
  '116-0002',
  '116-0011',
  '116-0013',
  '116-0012',
  '116-0014',
  '116-0001',
  '116-0003',
  '116-0000',
  '174-0044',
  '175-0092',
  '175-0093',
  '174-0051',
  '174-0055',
  '173-0004',
  '173-0002',
  '174-0061',
  '173-0035',
  '173-0032',
  '173-0031',
  '173-0024',
  '173-0023',
  '173-0014',
  '173-0033',
  '173-0003',
  '174-0076',
  '173-0025',
  '173-0037',
  '173-0034',
  '173-0015',
  '174-0043',
  '174-0075',
  '174-0053',
  '174-0056',
  '175-0081',
  '175-0085',
  '175-0082',
  '174-0074',
  '174-0071',
  '175-0083',
  '173-0016',
  '173-0005',
  '174-0064',
  '173-0022',
  '173-0026',
  '175-0094',
  '174-0045',
  '175-0045',
  '174-0052',
  '174-0046',
  '174-0042',
  '174-0073',
  '173-0013',
  '174-0062',
  '173-0011',
  '174-0041',
  '173-0001',
  '174-0063',
  '175-0091',
  '173-0027',
  '174-0072',
  '174-0054',
  '173-0036',
  '173-0012',
  '173-0021',
  '175-0084',
  '174-0065',
  '174-0000',
  '132-0024',
  '134-0092',
  '134-0082',
  '132-0013',
  '134-0013',
  '132-0022',
  '133-0042',
  '133-0041',
  '133-0054',
  '134-0081',
  '133-0051',
  '133-0053',
  '132-0034',
  '133-0073',
  '133-0072',
  '133-0061',
  '133-0064',
  '134-0087',
  '132-0021',
  '134-0083',
  '132-0001',
  '132-0023',
  '134-0088',
  '133-0057',
  '132-0032',
  '133-0055',
  '132-0015',
  '134-0015',
  '134-0093',
  '132-0003',
  '134-0003',
  '134-0084',
  '133-0052',
  '132-0033',
  '133-0063',
  '133-0062',
  '133-0071',
  '132-0014',
  '132-0035',
  '134-0091',
  '133-0044',
  '132-0025',
  '132-0031',
  '133-0043',
  '132-0011',
  '134-0085',
  '133-0056',
  '133-0065',
  '132-0002',
  '133-0002',
  '134-0086',
  '132-0000',
  '146-0082',
  '145-0061',
  '146-0091',
  '143-0014',
  '143-0012',
  '143-0015',
  '143-0013',
  '143-0016',
  '143-0011',
  '144-0052',
  '144-0053',
  '145-0064',
  '144-0032',
  '145-0062',
  '143-0021',
  '145-0073',
  '146-0085',
  '143-0003',
  '143-0023',
  '146-0092',
  '143-0002',
  '143-0004',
  '144-0054',
  '146-0095',
  '146-0083',
  '143-0024',
  '145-0071',
  '145-0072',
  '145-0076',
  '143-0001',
  '146-0081',
  '143-0027',
  '144-0055',
  '144-0051',
  '144-0034',
  '143-0026',
  '145-0075',
  '144-0056',
  '144-0047',
  '144-0043',
  '144-0042',
  '144-0041',
  '144-0031',
  '144-0033',
  '143-0022',
  '145-0074',
  '146-0094',
  '145-0065',
  '144-0046',
  '143-0007',
  '143-0006',
  '143-0005',
  '144-0044',
  '144-0035',
  '146-0084',
  '145-0063',
  '143-0025',
  '145-0066',
  '144-0045',
  '146-0093',
  '145-0067',
  '144-0000',
  '125-0062',
  '124-0022',
  '124-0003',
  '125-0042',
  '125-0043',
  '125-0053',
  '125-0061',
  '124-0001',
  '125-0052',
  '125-0063',
  '124-0024',
  '125-0054',
  '124-0005',
  '124-0012',
  '125-0051',
  '124-0002',
  '125-0002',
  '124-0025',
  '125-0031',
  '125-0041',
  '124-0023',
  '124-0013',
  '124-0004',
  '125-0033',
  '124-0014',
  '124-0021',
  '124-0006',
  '125-0032',
  '125-0034',
  '125-0035',
  '124-0011',
  '124-0000',
  '115-0045',
  '115-0053',
  '115-0055',
  '115-0044',
  '115-0052',
  '115-0041',
  '115-0051',
  '114-0002',
  '114-0022',
  '114-0034',
  '114-0016',
  '115-0043',
  '114-0021',
  '115-0054',
  '114-0005',
  '115-0042',
  '114-0033',
  '114-0031',
  '114-0011',
  '114-0023',
  '114-0014',
  '114-0012',
  '114-0003',
  '114-0015',
  '114-0032',
  '115-0056',
  '114-0024',
  '114-0001',
  '114-0013',
  '114-0004',
  '114-0000',
  '135-0064',
  '135-0063',
  '135-0014',
  '135-0012',
  '135-0034',
  '135-0051',
  '135-0044',
  '135-0011',
  '136-0072',
  '136-0071',
  '136-0073',
  '135-0042',
  '135-0024',
  '135-0031',
  '135-0003',
  '135-0043',
  '135-0052',
  '135-0062',
  '135-0021',
  '135-0007',
  '136-0082',
  '136-0075',
  '135-0002',
  '135-0015',
  '135-0013',
  '135-0005',
  '135-0053',
  '135-0065',
  '135-0016',
  '135-0006',
  '135-0047',
  '135-0061',
  '135-6090',
  '135-6001',
  '135-6002',
  '135-6003',
  '135-6004',
  '135-6005',
  '135-6006',
  '135-6007',
  '135-6008',
  '135-6009',
  '135-6010',
  '135-6011',
  '135-6012',
  '135-6013',
  '135-6014',
  '135-6015',
  '135-6016',
  '135-6017',
  '135-6018',
  '135-6019',
  '135-6020',
  '135-6021',
  '135-6022',
  '135-6023',
  '135-6024',
  '135-6025',
  '135-6026',
  '135-6027',
  '135-6028',
  '135-6029',
  '135-6030',
  '135-6031',
  '135-6032',
  '135-6033',
  '135-6034',
  '135-6035',
  '135-6036',
  '135-6037',
  '136-0074',
  '135-0023',
  '135-0033',
  '135-0032',
  '135-0041',
  '135-0045',
  '135-0046',
  '136-0076',
  '135-0022',
  '135-0001',
  '135-0004',
  '135-0048',
  '136-0081',
  '136-0083',
  '135-0000',
  '142-0063',
  '140-0014',
  '141-0032',
  '141-6090',
  '141-6001',
  '141-6002',
  '141-6003',
  '141-6004',
  '141-6005',
  '141-6006',
  '141-6007',
  '141-6008',
  '141-6009',
  '141-6010',
  '141-6011',
  '141-6012',
  '141-6013',
  '141-6014',
  '141-6015',
  '141-6016',
  '141-6017',
  '141-6018',
  '141-6019',
  '141-6020',
  '141-6021',
  '141-6022',
  '141-6023',
  '141-6024',
  '141-6025',
  '141-6026',
  '141-6027',
  '141-6028',
  '141-6029',
  '141-6030',
  '140-0012',
  '141-0021',
  '140-0001',
  '141-0001',
  '142-0062',
  '142-0061',
  '142-0041',
  '142-0053',
  '140-0015',
  '141-0031',
  '141-0033',
  '142-0054',
  '142-0064',
  '140-0011',
  '141-0022',
  '140-0002',
  '142-0052',
  '135-0092',
  '142-0051',
  '140-0005',
  '142-0043',
  '140-0013',
  '140-0004',
  '140-0003',
  '142-0042',
  '140-0000',
  '151-0064',
  '150-0032',
  '150-0042',
  '150-0013',
  '150-6090',
  '150-6001',
  '150-6002',
  '150-6003',
  '150-6004',
  '150-6005',
  '150-6006',
  '150-6007',
  '150-6008',
  '150-6009',
  '150-6010',
  '150-6011',
  '150-6012',
  '150-6013',
  '150-6014',
  '150-6015',
  '150-6016',
  '150-6017',
  '150-6018',
  '150-6019',
  '150-6020',
  '150-6021',
  '150-6022',
  '150-6023',
  '150-6024',
  '150-6025',
  '150-6026',
  '150-6027',
  '150-6028',
  '150-6029',
  '150-6030',
  '150-6031',
  '150-6032',
  '150-6033',
  '150-6034',
  '150-6035',
  '150-6036',
  '150-6037',
  '150-6038',
  '150-6039',
  '150-0021',
  '150-0022',
  '151-0065',
  '150-0047',
  '150-0031',
  '151-0073',
  '150-0033',
  '150-0002',
  '150-0046',
  '150-0001',
  '150-0045',
  '150-0041',
  '151-0051',
  '150-0034',
  '150-0043',
  '151-0063',
  '150-0036',
  '151-0066',
  '151-0072',
  '150-0035',
  '151-0061',
  '150-0011',
  '150-0012',
  '151-0071',
  '150-0044',
  '151-0062',
  '151-0053',
  '151-0052',
  '150-0000',
  '160-0005',
  '162-0803',
  '162-0817',
  '162-0824',
  '160-0007',
  '162-0062',
  '162-0856',
  '162-0842',
  '162-0846',
  '162-0848',
  '162-0843',
  '162-0066',
  '162-0847',
  '162-0064',
  '162-0844',
  '162-0826',
  '162-0845',
  '162-0063',
  '162-0061',
  '162-0857',
  '162-0832',
  '162-0806',
  '169-0072',
  '162-0802',
  '162-0823',
  '162-0825',
  '160-0013',
  '160-0001',
  '160-0021',
  '161-0034',
  '162-0054',
  '162-0044',
  '169-0074',
  '162-0834',
  '162-0853',
  '162-0838',
  '160-0017',
  '160-0016',
  '161-0033',
  '162-0822',
  '162-0816',
  '162-0814',
  '160-0022',
  '162-0811',
  '160-0018',
  '162-0065',
  '160-0015',
  '169-0075',
  '162-0833',
  '162-0818',
  '162-0821',
  '162-0815',
  '162-0808',
  '169-0071',
  '162-0067',
  '160-0008',
  '169-0052',
  '162-0052',
  '160-0014',
  '161-0035',
  '161-0032',
  '162-0804',
  '162-0835',
  '162-0837',
  '161-0031',
  '162-0812',
  '160-0023',
  '163-0790',
  '163-0701',
  '163-0702',
  '163-0703',
  '163-0704',
  '163-0705',
  '163-0706',
  '163-0707',
  '163-0708',
  '163-0709',
  '163-0710',
  '163-0711',
  '163-0712',
  '163-0713',
  '163-0714',
  '163-0715',
  '163-0716',
  '163-0717',
  '163-0718',
  '163-0719',
  '163-0720',
  '163-0721',
  '163-0722',
  '163-0723',
  '163-0724',
  '163-0725',
  '163-0726',
  '163-1390',
  '163-1301',
  '163-1302',
  '163-1303',
  '163-1304',
  '163-1305',
  '163-1306',
  '163-1307',
  '163-1308',
  '163-1309',
  '163-1310',
  '163-1311',
  '163-1312',
  '163-1313',
  '163-1314',
  '163-1315',
  '163-1316',
  '163-1317',
  '163-1318',
  '163-1319',
  '163-1320',
  '163-1321',
  '163-1322',
  '163-1323',
  '163-1324',
  '163-1325',
  '163-1326',
  '163-1327',
  '163-1328',
  '163-1329',
  '163-1330',
  '163-1331',
  '163-1332',
  '163-1333',
  '163-1334',
  '163-1335',
  '163-1336',
  '163-1337',
  '163-1338',
  '163-1339',
  '163-1340',
  '163-1341',
  '163-1342',
  '163-1343',
  '163-1344',
  '163-0890',
  '163-0801',
  '163-0802',
  '163-0803',
  '163-0804',
  '163-0805',
  '163-0806',
  '163-0807',
  '163-0808',
  '163-0809',
  '163-0810',
  '163-0811',
  '163-0812',
  '163-0813',
  '163-0814',
  '163-0815',
  '163-0816',
  '163-0817',
  '163-0818',
  '163-0819',
  '163-0820',
  '163-0821',
  '163-0822',
  '163-0823',
  '163-0824',
  '163-0825',
  '163-0826',
  '163-0827',
  '163-0828',
  '163-0829',
  '163-0830',
  '163-1590',
  '163-1501',
  '163-1502',
  '163-1503',
  '163-1504',
  '163-1505',
  '163-1506',
  '163-1507',
  '163-1508',
  '163-1509',
  '163-1510',
  '163-1511',
  '163-1512',
  '163-1513',
  '163-1514',
  '163-1515',
  '163-1516',
  '163-1517',
  '163-1518',
  '163-1519',
  '163-1520',
  '163-1521',
  '163-1522',
  '163-1523',
  '163-1524',
  '163-1525',
  '163-1526',
  '163-1527',
  '163-1528',
  '163-1529',
  '163-1530',
  '163-1531',
  '163-1190',
  '163-1101',
  '163-1102',
  '163-1103',
  '163-1104',
  '163-1105',
  '163-1106',
  '163-1107',
  '163-1108',
  '163-1109',
  '163-1110',
  '163-1111',
  '163-1112',
  '163-1113',
  '163-1114',
  '163-1115',
  '163-1116',
  '163-1117',
  '163-1118',
  '163-1119',
  '163-1120',
  '163-1121',
  '163-1122',
  '163-1123',
  '163-1124',
  '163-1125',
  '163-1126',
  '163-1127',
  '163-1128',
  '163-1129',
  '163-1130',
  '163-0290',
  '163-0201',
  '163-0202',
  '163-0203',
  '163-0204',
  '163-0205',
  '163-0206',
  '163-0207',
  '163-0208',
  '163-0209',
  '163-0210',
  '163-0211',
  '163-0212',
  '163-0213',
  '163-0214',
  '163-0215',
  '163-0216',
  '163-0217',
  '163-0218',
  '163-0219',
  '163-0220',
  '163-0221',
  '163-0222',
  '163-0223',
  '163-0224',
  '163-0225',
  '163-0226',
  '163-0227',
  '163-0228',
  '163-0229',
  '163-0230',
  '163-0231',
  '163-0232',
  '163-0233',
  '163-0234',
  '163-0235',
  '163-0236',
  '163-0237',
  '163-0238',
  '163-0239',
  '163-0240',
  '163-0241',
  '163-0242',
  '163-0243',
  '163-0244',
  '163-0245',
  '163-0246',
  '163-0247',
  '163-0248',
  '163-0249',
  '163-0250',
  '163-0251',
  '163-0252',
  '163-0690',
  '163-0601',
  '163-0602',
  '163-0603',
  '163-0604',
  '163-0605',
  '163-0606',
  '163-0607',
  '163-0608',
  '163-0609',
  '163-0610',
  '163-0611',
  '163-0612',
  '163-0613',
  '163-0614',
  '163-0615',
  '163-0616',
  '163-0617',
  '163-0618',
  '163-0619',
  '163-0620',
  '163-0621',
  '163-0622',
  '163-0623',
  '163-0624',
  '163-0625',
  '163-0626',
  '163-0627',
  '163-0628',
  '163-0629',
  '163-0630',
  '163-0631',
  '163-0632',
  '163-0633',
  '163-0634',
  '163-0635',
  '163-0636',
  '163-0637',
  '163-0638',
  '163-0639',
  '163-0640',
  '163-0641',
  '163-0642',
  '163-0643',
  '163-0644',
  '163-0645',
  '163-0646',
  '163-0647',
  '163-0648',
  '163-0649',
  '163-0650',
  '163-0651',
  '163-0652',
  '163-0653',
  '163-0654',
  '163-0590',
  '163-0501',
  '163-0502',
  '163-0503',
  '163-0504',
  '163-0505',
  '163-0506',
  '163-0507',
  '163-0508',
  '163-0509',
  '163-0510',
  '163-0511',
  '163-0512',
  '163-0513',
  '163-0514',
  '163-0515',
  '163-0516',
  '163-0517',
  '163-0518',
  '163-0519',
  '163-0520',
  '163-0521',
  '163-0522',
  '163-0523',
  '163-0524',
  '163-0525',
  '163-0526',
  '163-0527',
  '163-0528',
  '163-0529',
  '163-0530',
  '163-0531',
  '163-0532',
  '163-0533',
  '163-0534',
  '163-0535',
  '163-0536',
  '163-0537',
  '163-0538',
  '163-0539',
  '163-0540',
  '163-0541',
  '163-0542',
  '163-0543',
  '163-0544',
  '163-0545',
  '163-0546',
  '163-0547',
  '163-0548',
  '163-0549',
  '163-0550',
  '163-1090',
  '163-1001',
  '163-1002',
  '163-1003',
  '163-1004',
  '163-1005',
  '163-1006',
  '163-1007',
  '163-1008',
  '163-1009',
  '163-1010',
  '163-1011',
  '163-1012',
  '163-1013',
  '163-1014',
  '163-1015',
  '163-1016',
  '163-1017',
  '163-1018',
  '163-1019',
  '163-1020',
  '163-1021',
  '163-1022',
  '163-1023',
  '163-1024',
  '163-1025',
  '163-1026',
  '163-1027',
  '163-1028',
  '163-1029',
  '163-1030',
  '163-1031',
  '163-1032',
  '163-1033',
  '163-1034',
  '163-1035',
  '163-1036',
  '163-1037',
  '163-1038',
  '163-1039',
  '163-1040',
  '163-1041',
  '163-1042',
  '163-1043',
  '163-1044',
  '163-1045',
  '163-1046',
  '163-1047',
  '163-1048',
  '163-1049',
  '163-1050',
  '163-1051',
  '163-1052',
  '163-0490',
  '163-0401',
  '163-0402',
  '163-0403',
  '163-0404',
  '163-0405',
  '163-0406',
  '163-0407',
  '163-0408',
  '163-0409',
  '163-0410',
  '163-0411',
  '163-0412',
  '163-0413',
  '163-0414',
  '163-0415',
  '163-0416',
  '163-0417',
  '163-0418',
  '163-0419',
  '163-0420',
  '163-0421',
  '163-0422',
  '163-0423',
  '163-0424',
  '163-0425',
  '163-0426',
  '163-0427',
  '163-0428',
  '163-0429',
  '163-0430',
  '163-0431',
  '163-0432',
  '163-0433',
  '163-0434',
  '163-0435',
  '163-0436',
  '163-0437',
  '163-0438',
  '163-0439',
  '163-0440',
  '163-0441',
  '163-0442',
  '163-0443',
  '163-0444',
  '163-0445',
  '163-0446',
  '163-0447',
  '163-0448',
  '163-0449',
  '163-0450',
  '163-0451',
  '163-0452',
  '163-0453',
  '163-0454',
  '163-0455',
  '163-0990',
  '163-0901',
  '163-0902',
  '163-0903',
  '163-0904',
  '163-0905',
  '163-0906',
  '163-0907',
  '163-0908',
  '163-0909',
  '163-0910',
  '163-0911',
  '163-0912',
  '163-0913',
  '163-0914',
  '163-0915',
  '163-0916',
  '163-0917',
  '163-0918',
  '163-0919',
  '163-0920',
  '163-0921',
  '163-0922',
  '163-0923',
  '163-0924',
  '163-0925',
  '163-0926',
  '163-0927',
  '163-0928',
  '163-0929',
  '163-0930',
  '163-6090',
  '163-6001',
  '163-6002',
  '163-6003',
  '163-6004',
  '163-6005',
  '163-6006',
  '163-6007',
  '163-6008',
  '163-6009',
  '163-6010',
  '163-6011',
  '163-6012',
  '163-6013',
  '163-6014',
  '163-6015',
  '163-6016',
  '163-6017',
  '163-6018',
  '163-6019',
  '163-6020',
  '163-6021',
  '163-6022',
  '163-6023',
  '163-6024',
  '163-6025',
  '163-6026',
  '163-6027',
  '163-6028',
  '163-6029',
  '163-6030',
  '163-6031',
  '163-6032',
  '163-6033',
  '163-6034',
  '163-6035',
  '163-6036',
  '163-6037',
  '163-6038',
  '160-6190',
  '160-6101',
  '160-6102',
  '160-6103',
  '160-6104',
  '160-6105',
  '160-6106',
  '160-6107',
  '160-6108',
  '160-6109',
  '160-6110',
  '160-6111',
  '160-6112',
  '160-6113',
  '160-6114',
  '160-6115',
  '160-6116',
  '160-6117',
  '160-6118',
  '160-6119',
  '160-6120',
  '160-6121',
  '160-6122',
  '160-6123',
  '160-6124',
  '160-6125',
  '160-6126',
  '160-6127',
  '160-6128',
  '160-6129',
  '160-6130',
  '160-6131',
  '160-6132',
  '160-6133',
  '160-6134',
  '160-6135',
  '160-6136',
  '160-6137',
  '160-6138',
  '160-6139',
  '163-1490',
  '163-1401',
  '163-1402',
  '163-1403',
  '163-1404',
  '163-1405',
  '163-1406',
  '163-1407',
  '163-1408',
  '163-1409',
  '163-1410',
  '163-1411',
  '163-1412',
  '163-1413',
  '163-1414',
  '163-1415',
  '163-1416',
  '163-1417',
  '163-1418',
  '163-1419',
  '163-1420',
  '163-1421',
  '163-1422',
  '163-1423',
  '163-1424',
  '163-1425',
  '163-1426',
  '163-1427',
  '163-1428',
  '163-1429',
  '163-1430',
  '163-1431',
  '163-1432',
  '163-1433',
  '163-1434',
  '163-1435',
  '163-1436',
  '163-1437',
  '163-1438',
  '163-1439',
  '163-1440',
  '163-1441',
  '163-1442',
  '163-1443',
  '163-1444',
  '163-1445',
  '163-1446',
  '163-1447',
  '163-1448',
  '163-1449',
  '163-1450',
  '163-1451',
  '163-1452',
  '163-1453',
  '163-1454',
  '162-0855',
  '162-0051',
  '169-0051',
  '162-0045',
  '162-0841',
  '162-0053',
  '162-0807',
  '162-0813',
  '169-0073',
  '162-0828',
  '160-0006',
  '162-0851',
  '160-0003',
  '162-0852',
  '162-0836',
  '160-0012',
  '162-0854',
  '162-0801',
  '162-0805',
  '162-0831',
  '162-0055',
  '160-0004',
  '160-0002',
  '160-0011',
  '162-0056',
  '162-0827',
  '162-0041',
  '162-0043',
  '162-0042',
  '160-0000',
  '166-0004',
  '166-0001',
  '167-0032',
  '167-0021',
  '168-0063',
  '167-0035',
  '166-0011',
  '168-0064',
  '168-0061',
  '167-0051',
  '167-0023',
  '167-0043',
  '168-0074',
  '168-0082',
  '166-0003',
  '166-0002',
  '167-0033',
  '167-0022',
  '168-0073',
  '167-0054',
  '167-0041',
  '168-0072',
  '168-0071',
  '166-0015',
  '166-0016',
  '167-0053',
  '167-0042',
  '168-0065',
  '168-0062',
  '166-0013',
  '167-0031',
  '166-0014',
  '167-0052',
  '168-0081',
  '167-0034',
  '166-0012',
  '166-0000',
  '130-0001',
  '130-0011',
  '131-0045',
  '130-0014',
  '130-0024',
  '131-0046',
  '130-0013',
  '130-0022',
  '131-0031',
  '130-0012',
  '131-0043',
  '130-0023',
  '130-0025',
  '131-0034',
  '130-0002',
  '130-0005',
  '131-0042',
  '131-0032',
  '131-0044',
  '130-0004',
  '130-0021',
  '131-0033',
  '131-0041',
  '130-0015',
  '130-0003',
  '130-0026',
  '130-0000',
  '156-0044',
  '154-0001',
  '157-0068',
  '154-0022',
  '157-0074',
  '156-0041',
  '157-0076',
  '158-0083',
  '158-0086',
  '157-0063',
  '157-0077',
  '154-0011',
  '156-0057',
  '157-0065',
  '158-0093',
  '158-0098',
  '157-0061',
  '155-0031',
  '157-0067',
  '157-0073',
  '157-0075',
  '157-0064',
  '156-0052',
  '154-0021',
  '154-0012',
  '154-0013',
  '156-0053',
  '156-0054',
  '156-0045',
  '154-0015',
  '154-0024',
  '154-0002',
  '154-0014',
  '157-0066',
  '158-0095',
  '154-0017',
  '157-0072',
  '155-0032',
  '154-0004',
  '155-0033',
  '158-0094',
  '158-0096',
  '158-0085',
  '158-0087',
  '157-0071',
  '154-0016',
  '158-0082',
  '158-0091',
  '158-0092',
  '154-0003',
  '156-0056',
  '156-0042',
  '158-0084',
  '158-0081',
  '156-0055',
  '156-0043',
  '154-0005',
  '157-0062',
  '156-0051',
  '158-0097',
  '154-0023',
  '154-0000',
  '110-0006',
  '111-0032',
  '111-0053',
  '110-0008',
  '111-0024',
  '110-0013',
  '110-0005',
  '110-0007',
  '110-0002',
  '111-0034',
  '110-0014',
  '111-0022',
  '111-0051',
  '111-0056',
  '111-0042',
  '111-0043',
  '110-0004',
  '111-0031',
  '110-0016',
  '111-0054',
  '111-0035',
  '111-0021',
  '110-0003',
  '111-0023',
  '111-0033',
  '111-0025',
  '110-0015',
  '111-0036',
  '111-0055',
  '110-0011',
  '111-0041',
  '110-0001',
  '111-0052',
  '110-0012',
  '110-0000',
  '103-6190',
  '103-6101',
  '103-6102',
  '103-6103',
  '103-6104',
  '103-6105',
  '103-6106',
  '103-6107',
  '103-6108',
  '103-6109',
  '103-6110',
  '103-6111',
  '103-6112',
  '103-6113',
  '103-6114',
  '103-6115',
  '103-6116',
  '103-6117',
  '103-6118',
  '103-6119',
  '103-6120',
  '103-6121',
  '103-6122',
  '103-6123',
  '103-6124',
  '103-6125',
  '103-6126',
  '103-6127',
  '103-6128',
  '103-6129',
  '103-6130',
  '103-6131',
  '103-6132',
  '104-0044',
  '104-0042',
  '104-0054',
  '104-0031',
  '104-0061',
  '104-0033',
  '104-0041',
  '104-0045',
  '104-0052',
  '104-0051',
  '104-0055',
  '103-0027',
  '103-6090',
  '103-6001',
  '103-6002',
  '103-6003',
  '103-6004',
  '103-6005',
  '103-6006',
  '103-6007',
  '103-6008',
  '103-6009',
  '103-6010',
  '103-6011',
  '103-6012',
  '103-6013',
  '103-6014',
  '103-6015',
  '103-6016',
  '103-6017',
  '103-6018',
  '103-6019',
  '103-6020',
  '103-6021',
  '103-6022',
  '103-6023',
  '103-6024',
  '103-6025',
  '103-6026',
  '103-6027',
  '103-6028',
  '103-6029',
  '103-6030',
  '103-6031',
  '103-6032',
  '103-6033',
  '103-6034',
  '103-6035',
  '103-0011',
  '103-0014',
  '103-0026',
  '103-0025',
  '103-0016',
  '103-0001',
  '103-0024',
  '103-0006',
  '103-0008',
  '103-0013',
  '103-0002',
  '103-0015',
  '103-0007',
  '103-0005',
  '103-0012',
  '103-0021',
  '103-0023',
  '103-0022',
  '103-0003',
  '104-0032',
  '104-0046',
  '104-0053',
  '104-6090',
  '104-6001',
  '104-6002',
  '104-6003',
  '104-6004',
  '104-6005',
  '104-6006',
  '104-6007',
  '104-6008',
  '104-6009',
  '104-6010',
  '104-6011',
  '104-6012',
  '104-6013',
  '104-6014',
  '104-6015',
  '104-6016',
  '104-6017',
  '104-6018',
  '104-6019',
  '104-6020',
  '104-6021',
  '104-6022',
  '104-6023',
  '104-6024',
  '104-6025',
  '104-6026',
  '104-6027',
  '104-6028',
  '104-6029',
  '104-6030',
  '104-6031',
  '104-6032',
  '104-6033',
  '104-6034',
  '104-6035',
  '104-6036',
  '104-6037',
  '104-6038',
  '104-6039',
  '104-6040',
  '104-6041',
  '104-6042',
  '104-6043',
  '104-6044',
  '104-6190',
  '104-6101',
  '104-6102',
  '104-6103',
  '104-6104',
  '104-6105',
  '104-6106',
  '104-6107',
  '104-6108',
  '104-6109',
  '104-6110',
  '104-6111',
  '104-6112',
  '104-6113',
  '104-6114',
  '104-6115',
  '104-6116',
  '104-6117',
  '104-6118',
  '104-6119',
  '104-6120',
  '104-6121',
  '104-6122',
  '104-6123',
  '104-6124',
  '104-6125',
  '104-6126',
  '104-6127',
  '104-6128',
  '104-6129',
  '104-6130',
  '104-6131',
  '104-6132',
  '104-6133',
  '104-6134',
  '104-6135',
  '104-6136',
  '104-6137',
  '104-6138',
  '104-6139',
  '104-6290',
  '104-6201',
  '104-6202',
  '104-6203',
  '104-6204',
  '104-6205',
  '104-6206',
  '104-6207',
  '104-6208',
  '104-6209',
  '104-6210',
  '104-6211',
  '104-6212',
  '104-6213',
  '104-6214',
  '104-6215',
  '104-6216',
  '104-6217',
  '104-6218',
  '104-6219',
  '104-6220',
  '104-6221',
  '104-6222',
  '104-6223',
  '104-6224',
  '104-6225',
  '104-6226',
  '104-6227',
  '104-6228',
  '104-6229',
  '104-6230',
  '104-6231',
  '104-6232',
  '104-6233',
  '103-0004',
  '104-0043',
  '103-0028',
  '104-0028',
  '103-0000',
  '102-0072',
  '102-0082',
  '101-0032',
  '101-0047',
  '100-0011',
  '100-0004',
  '100-6890',
  '100-6801',
  '100-6802',
  '100-6803',
  '100-6804',
  '100-6805',
  '100-6806',
  '100-6807',
  '100-6808',
  '100-6809',
  '100-6810',
  '100-6811',
  '100-6812',
  '100-6813',
  '100-6814',
  '100-6815',
  '100-6816',
  '100-6817',
  '100-6818',
  '100-6819',
  '100-6820',
  '100-6821',
  '100-6822',
  '100-6823',
  '100-6824',
  '100-6825',
  '100-6826',
  '100-6827',
  '100-6828',
  '100-6829',
  '100-6830',
  '100-6831',
  '100-6832',
  '100-6833',
  '100-6834',
  '100-6835',
  '100-6836',
  '100-6837',
  '101-0044',
  '100-0013',
  '100-6090',
  '100-6001',
  '100-6002',
  '100-6003',
  '100-6004',
  '100-6005',
  '100-6006',
  '100-6007',
  '100-6008',
  '100-6009',
  '100-6010',
  '100-6011',
  '100-6012',
  '100-6013',
  '100-6014',
  '100-6015',
  '100-6016',
  '100-6017',
  '100-6018',
  '100-6019',
  '100-6020',
  '100-6021',
  '100-6022',
  '100-6023',
  '100-6024',
  '100-6025',
  '100-6026',
  '100-6027',
  '100-6028',
  '100-6029',
  '100-6030',
  '100-6031',
  '100-6032',
  '100-6033',
  '100-6034',
  '100-6035',
  '100-6036',
  '101-0029',
  '101-0063',
  '101-0024',
  '101-0033',
  '101-0052',
  '101-0045',
  '101-0036',
  '101-0035',
  '101-0026',
  '101-0025',
  '101-0051',
  '101-0041',
  '101-0062',
  '101-0046',
  '101-0048',
  '101-0043',
  '101-0054',
  '101-0037',
  '101-0022',
  '101-0028',
  '101-0034',
  '101-0042',
  '101-0027',
  '101-0023',
  '101-0038',
  '101-0053',
  '102-0094',
  '102-0091',
  '102-0074',
  '102-0073',
  '100-0002',
  '102-0083',
  '102-0076',
  '101-0064',
  '102-0075',
  '101-0021',
  '100-0001',
  '100-0014',
  '100-6190',
  '100-6101',
  '100-6102',
  '100-6103',
  '100-6104',
  '100-6105',
  '100-6106',
  '100-6107',
  '100-6108',
  '100-6109',
  '100-6110',
  '100-6111',
  '100-6112',
  '100-6113',
  '100-6114',
  '100-6115',
  '100-6116',
  '100-6117',
  '100-6118',
  '100-6119',
  '100-6120',
  '100-6121',
  '100-6122',
  '100-6123',
  '100-6124',
  '100-6125',
  '100-6126',
  '100-6127',
  '100-6128',
  '100-6129',
  '100-6130',
  '100-6131',
  '100-6132',
  '100-6133',
  '100-6134',
  '100-6135',
  '100-6136',
  '100-6137',
  '100-6138',
  '100-6139',
  '100-6140',
  '100-6141',
  '100-6142',
  '100-6143',
  '100-6144',
  '101-0065',
  '102-0084',
  '102-0092',
  '101-0031',
  '100-0003',
  '101-0003',
  '100-0012',
  '102-0093',
  '102-0071',
  '100-0005',
  '100-6690',
  '100-6601',
  '100-6602',
  '100-6603',
  '100-6604',
  '100-6605',
  '100-6606',
  '100-6607',
  '100-6608',
  '100-6609',
  '100-6610',
  '100-6611',
  '100-6612',
  '100-6613',
  '100-6614',
  '100-6615',
  '100-6616',
  '100-6617',
  '100-6618',
  '100-6619',
  '100-6620',
  '100-6621',
  '100-6622',
  '100-6623',
  '100-6624',
  '100-6625',
  '100-6626',
  '100-6627',
  '100-6628',
  '100-6629',
  '100-6630',
  '100-6631',
  '100-6632',
  '100-6633',
  '100-6634',
  '100-6635',
  '100-6636',
  '100-6637',
  '100-6638',
  '100-6639',
  '100-6640',
  '100-6641',
  '100-6642',
  '100-6790',
  '100-6701',
  '100-6702',
  '100-6703',
  '100-6704',
  '100-6705',
  '100-6706',
  '100-6707',
  '100-6708',
  '100-6709',
  '100-6710',
  '100-6711',
  '100-6712',
  '100-6713',
  '100-6714',
  '100-6715',
  '100-6716',
  '100-6717',
  '100-6718',
  '100-6719',
  '100-6720',
  '100-6721',
  '100-6722',
  '100-6723',
  '100-6724',
  '100-6725',
  '100-6726',
  '100-6727',
  '100-6728',
  '100-6729',
  '100-6730',
  '100-6731',
  '100-6732',
  '100-6733',
  '100-6734',
  '100-6735',
  '100-6736',
  '100-6737',
  '100-6738',
  '100-6739',
  '100-6740',
  '100-6741',
  '100-6742',
  '100-6743',
  '100-7090',
  '100-7001',
  '100-7002',
  '100-7003',
  '100-7004',
  '100-7005',
  '100-7006',
  '100-7007',
  '100-7008',
  '100-7009',
  '100-7010',
  '100-7011',
  '100-7012',
  '100-7013',
  '100-7014',
  '100-7015',
  '100-7016',
  '100-7017',
  '100-7018',
  '100-7019',
  '100-7020',
  '100-7021',
  '100-7022',
  '100-7023',
  '100-7024',
  '100-7025',
  '100-7026',
  '100-7027',
  '100-7028',
  '100-7029',
  '100-7030',
  '100-7031',
  '100-7032',
  '100-7033',
  '100-7034',
  '100-7035',
  '100-7036',
  '100-7037',
  '100-7038',
  '100-6590',
  '100-6501',
  '100-6502',
  '100-6503',
  '100-6504',
  '100-6505',
  '100-6506',
  '100-6507',
  '100-6508',
  '100-6509',
  '100-6510',
  '100-6511',
  '100-6512',
  '100-6513',
  '100-6514',
  '100-6515',
  '100-6516',
  '100-6517',
  '100-6518',
  '100-6519',
  '100-6520',
  '100-6521',
  '100-6522',
  '100-6523',
  '100-6524',
  '100-6525',
  '100-6526',
  '100-6527',
  '100-6528',
  '100-6529',
  '100-6530',
  '100-6531',
  '100-6532',
  '100-6533',
  '100-6534',
  '100-6535',
  '100-6536',
  '100-6537',
  '100-6538',
  '100-6490',
  '100-6401',
  '100-6402',
  '100-6403',
  '100-6404',
  '100-6405',
  '100-6406',
  '100-6407',
  '100-6408',
  '100-6409',
  '100-6410',
  '100-6411',
  '100-6412',
  '100-6413',
  '100-6414',
  '100-6415',
  '100-6416',
  '100-6417',
  '100-6418',
  '100-6419',
  '100-6420',
  '100-6421',
  '100-6422',
  '100-6423',
  '100-6424',
  '100-6425',
  '100-6426',
  '100-6427',
  '100-6428',
  '100-6429',
  '100-6430',
  '100-6431',
  '100-6432',
  '100-6433',
  '100-6290',
  '100-6201',
  '100-6202',
  '100-6203',
  '100-6204',
  '100-6205',
  '100-6206',
  '100-6207',
  '100-6208',
  '100-6209',
  '100-6210',
  '100-6211',
  '100-6212',
  '100-6213',
  '100-6214',
  '100-6215',
  '100-6216',
  '100-6217',
  '100-6218',
  '100-6219',
  '100-6220',
  '100-6221',
  '100-6222',
  '100-6223',
  '100-6224',
  '100-6225',
  '100-6226',
  '100-6227',
  '100-6228',
  '100-6229',
  '100-6230',
  '100-6231',
  '100-6990',
  '100-6901',
  '100-6902',
  '100-6903',
  '100-6904',
  '100-6905',
  '100-6906',
  '100-6907',
  '100-6908',
  '100-6909',
  '100-6910',
  '100-6911',
  '100-6912',
  '100-6913',
  '100-6914',
  '100-6915',
  '100-6916',
  '100-6917',
  '100-6918',
  '100-6919',
  '100-6920',
  '100-6921',
  '100-6922',
  '100-6923',
  '100-6924',
  '100-6925',
  '100-6926',
  '100-6927',
  '100-6928',
  '100-6929',
  '100-6930',
  '100-6931',
  '100-6932',
  '100-6933',
  '100-6934',
  '100-6390',
  '100-6301',
  '100-6302',
  '100-6303',
  '100-6304',
  '100-6305',
  '100-6306',
  '100-6307',
  '100-6308',
  '100-6309',
  '100-6310',
  '100-6311',
  '100-6312',
  '100-6313',
  '100-6314',
  '100-6315',
  '100-6316',
  '100-6317',
  '100-6318',
  '100-6319',
  '100-6320',
  '100-6321',
  '100-6322',
  '100-6323',
  '100-6324',
  '100-6325',
  '100-6326',
  '100-6327',
  '100-6328',
  '100-6329',
  '100-6330',
  '100-6331',
  '100-6332',
  '100-6333',
  '100-6334',
  '100-6335',
  '100-6336',
  '100-6337',
  '101-0061',
  '100-0006',
  '102-0081',
  '102-0085',
  '100-0000',
  '170-0014',
  '171-0014',
  '170-0011',
  '171-0043',
  '170-0012',
  '170-0004',
  '170-0003',
  '170-0002',
  '171-0041',
  '171-0032',
  '171-0033',
  '171-0042',
  '171-0044',
  '171-0051',
  '171-0021',
  '170-0001',
  '170-0013',
  '170-6090',
  '170-6001',
  '170-6002',
  '170-6003',
  '170-6004',
  '170-6005',
  '170-6006',
  '170-6007',
  '170-6008',
  '170-6009',
  '170-6010',
  '170-6011',
  '170-6012',
  '170-6013',
  '170-6014',
  '170-6015',
  '170-6016',
  '170-6017',
  '170-6018',
  '170-6019',
  '170-6020',
  '170-6021',
  '170-6022',
  '170-6023',
  '170-6024',
  '170-6025',
  '170-6026',
  '170-6027',
  '170-6028',
  '170-6029',
  '170-6030',
  '170-6031',
  '170-6032',
  '170-6033',
  '170-6034',
  '170-6035',
  '170-6036',
  '170-6037',
  '170-6038',
  '170-6039',
  '170-6040',
  '170-6041',
  '170-6042',
  '170-6043',
  '170-6044',
  '170-6045',
  '170-6046',
  '170-6047',
  '170-6048',
  '170-6049',
  '170-6050',
  '170-6051',
  '170-6052',
  '170-6053',
  '170-6054',
  '170-6055',
  '170-6056',
  '170-6057',
  '170-6058',
  '170-6059',
  '170-6060',
  '171-0022',
  '170-0005',
  '171-0052',
  '171-0031',
  '170-0000',
  '165-0026',
  '165-0022',
  '165-0023',
  '165-0031',
  '164-0002',
  '165-0032',
  '165-0035',
  '164-0011',
  '164-0001',
  '165-0025',
  '165-0027',
  '164-0003',
  '164-0012',
  '165-0024',
  '165-0021',
  '164-0014',
  '165-0034',
  '164-0013',
  '165-0033',
  '164-0000',
  '176-0005',
  '179-0071',
  '178-0061',
  '178-0062',
  '179-0074',
  '177-0044',
  '177-0043',
  '179-0081',
  '176-0022',
  '176-0004',
  '176-0006',
  '176-0002',
  '177-0042',
  '177-0045',
  '177-0041',
  '177-0052',
  '177-0053',
  '177-0051',
  '177-0033',
  '179-0075',
  '179-0073',
  '177-0054',
  '179-0076',
  '176-0011',
  '176-0013',
  '176-0014',
  '176-0012',
  '176-0024',
  '176-0025',
  '176-0023',
  '178-0065',
  '178-0066',
  '179-0082',
  '176-0021',
  '176-0001',
  '176-0003',
  '179-0085',
  '178-0063',
  '179-0072',
  '179-0084',
  '177-0034',
  '179-0083',
  '178-0064',
  '177-0035',
  '177-0031',
  '177-0032',
  '176-0000',
  '112-0012',
  '112-0013',
  '112-0003',
  '112-0002',
  '112-0004',
  '112-0006',
  '112-0005',
  '112-0014',
  '112-0011',
  '113-0022',
  '113-0024',
  '113-0031',
  '113-0001',
  '112-0001',
  '113-0033',
  '113-0021',
  '113-0023',
  '112-0015',
  '113-0032',
  '113-0034',
  '112-0000',
  '107-0052',
  '107-6090',
  '107-6001',
  '107-6002',
  '107-6003',
  '107-6004',
  '107-6005',
  '107-6006',
  '107-6007',
  '107-6008',
  '107-6009',
  '107-6010',
  '107-6011',
  '107-6012',
  '107-6013',
  '107-6014',
  '107-6015',
  '107-6016',
  '107-6017',
  '107-6018',
  '107-6019',
  '107-6020',
  '107-6021',
  '107-6022',
  '107-6023',
  '107-6024',
  '107-6025',
  '107-6026',
  '107-6027',
  '107-6028',
  '107-6029',
  '107-6030',
  '107-6031',
  '107-6032',
  '107-6033',
  '107-6034',
  '107-6035',
  '107-6036',
  '107-6037',
  '107-6190',
  '107-6101',
  '107-6102',
  '107-6103',
  '107-6104',
  '107-6105',
  '107-6106',
  '107-6107',
  '107-6108',
  '107-6109',
  '107-6110',
  '107-6111',
  '107-6112',
  '107-6113',
  '107-6114',
  '107-6115',
  '107-6116',
  '107-6117',
  '107-6118',
  '107-6119',
  '107-6120',
  '107-6121',
  '107-6122',
  '107-6123',
  '107-6124',
  '107-6125',
  '107-6126',
  '107-6127',
  '107-6128',
  '107-6129',
  '107-6130',
  '107-6390',
  '107-6301',
  '107-6302',
  '107-6303',
  '107-6304',
  '107-6305',
  '107-6306',
  '107-6307',
  '107-6308',
  '107-6309',
  '107-6310',
  '107-6311',
  '107-6312',
  '107-6313',
  '107-6314',
  '107-6315',
  '107-6316',
  '107-6317',
  '107-6318',
  '107-6319',
  '107-6320',
  '107-6321',
  '107-6322',
  '107-6323',
  '107-6324',
  '107-6325',
  '107-6326',
  '107-6327',
  '107-6328',
  '107-6329',
  '107-6330',
  '107-6331',
  '107-6332',
  '107-6333',
  '107-6334',
  '107-6335',
  '107-6336',
  '107-6337',
  '107-6338',
  '107-6339',
  '107-6290',
  '107-6201',
  '107-6202',
  '107-6203',
  '107-6204',
  '107-6205',
  '107-6206',
  '107-6207',
  '107-6208',
  '107-6209',
  '107-6210',
  '107-6211',
  '107-6212',
  '107-6213',
  '107-6214',
  '107-6215',
  '107-6216',
  '107-6217',
  '107-6218',
  '107-6219',
  '107-6220',
  '107-6221',
  '107-6222',
  '107-6223',
  '107-6224',
  '107-6225',
  '107-6226',
  '107-6227',
  '107-6228',
  '107-6229',
  '107-6230',
  '107-6231',
  '107-6232',
  '107-6233',
  '107-6234',
  '107-6235',
  '107-6236',
  '107-6237',
  '107-6238',
  '107-6239',
  '107-6240',
  '107-6241',
  '107-6242',
  '107-6243',
  '107-6244',
  '107-6245',
  '106-0045',
  '106-0041',
  '106-0043',
  '106-0042',
  '105-0002',
  '105-6290',
  '105-6201',
  '105-6202',
  '105-6203',
  '105-6204',
  '105-6205',
  '105-6206',
  '105-6207',
  '105-6208',
  '105-6209',
  '105-6210',
  '105-6211',
  '105-6212',
  '105-6213',
  '105-6214',
  '105-6215',
  '105-6216',
  '105-6217',
  '105-6218',
  '105-6219',
  '105-6220',
  '105-6221',
  '105-6222',
  '105-6223',
  '105-6224',
  '105-6225',
  '105-6226',
  '105-6227',
  '105-6228',
  '105-6229',
  '105-6230',
  '105-6231',
  '105-6232',
  '105-6233',
  '105-6234',
  '105-6235',
  '105-6236',
  '105-6237',
  '105-6238',
  '105-6239',
  '105-6240',
  '105-6241',
  '105-6242',
  '105-0022',
  '108-0022',
  '107-0061',
  '108-0075',
  '108-6090',
  '108-6001',
  '108-6002',
  '108-6003',
  '108-6004',
  '108-6005',
  '108-6006',
  '108-6007',
  '108-6008',
  '108-6009',
  '108-6010',
  '108-6011',
  '108-6012',
  '108-6013',
  '108-6014',
  '108-6015',
  '108-6016',
  '108-6017',
  '108-6018',
  '108-6019',
  '108-6020',
  '108-6021',
  '108-6022',
  '108-6023',
  '108-6024',
  '108-6025',
  '108-6026',
  '108-6027',
  '108-6028',
  '108-6029',
  '108-6030',
  '108-6031',
  '108-6032',
  '108-6190',
  '108-6101',
  '108-6102',
  '108-6103',
  '108-6104',
  '108-6105',
  '108-6106',
  '108-6107',
  '108-6108',
  '108-6109',
  '108-6110',
  '108-6111',
  '108-6112',
  '108-6113',
  '108-6114',
  '108-6115',
  '108-6116',
  '108-6117',
  '108-6118',
  '108-6119',
  '108-6120',
  '108-6121',
  '108-6122',
  '108-6123',
  '108-6124',
  '108-6125',
  '108-6126',
  '108-6127',
  '108-6128',
  '108-6129',
  '108-6130',
  '108-6131',
  '108-6290',
  '108-6201',
  '108-6202',
  '108-6203',
  '108-6204',
  '108-6205',
  '108-6206',
  '108-6207',
  '108-6208',
  '108-6209',
  '108-6210',
  '108-6211',
  '108-6212',
  '108-6213',
  '108-6214',
  '108-6215',
  '108-6216',
  '108-6217',
  '108-6218',
  '108-6219',
  '108-6220',
  '108-6221',
  '108-6222',
  '108-6223',
  '108-6224',
  '108-6225',
  '108-6226',
  '108-6227',
  '108-6228',
  '108-6229',
  '108-6230',
  '108-6231',
  '105-0014',
  '108-0014',
  '105-0023',
  '108-0023',
  '105-0011',
  '105-0012',
  '108-0072',
  '108-0071',
  '105-0004',
  '135-0091',
  '108-0074',
  '105-0001',
  '105-6090',
  '105-6001',
  '105-6002',
  '105-6003',
  '105-6004',
  '105-6005',
  '105-6006',
  '105-6007',
  '105-6008',
  '105-6009',
  '105-6010',
  '105-6011',
  '105-6012',
  '105-6013',
  '105-6014',
  '105-6015',
  '105-6016',
  '105-6017',
  '105-6018',
  '105-6019',
  '105-6020',
  '105-6021',
  '105-6022',
  '105-6023',
  '105-6024',
  '105-6025',
  '105-6026',
  '105-6027',
  '105-6028',
  '105-6029',
  '105-6030',
  '105-6031',
  '105-6032',
  '105-6033',
  '105-6034',
  '105-6035',
  '105-6036',
  '105-6037',
  '105-6390',
  '105-6301',
  '105-6302',
  '105-6303',
  '105-6304',
  '105-6305',
  '105-6306',
  '105-6307',
  '105-6308',
  '105-6309',
  '105-6310',
  '105-6311',
  '105-6312',
  '105-6313',
  '105-6314',
  '105-6315',
  '105-6316',
  '105-6317',
  '105-6318',
  '105-6319',
  '105-6320',
  '105-6321',
  '105-6322',
  '105-6323',
  '105-6324',
  '105-6325',
  '105-6326',
  '105-6327',
  '105-6328',
  '105-6329',
  '105-6330',
  '105-6331',
  '105-6332',
  '105-6333',
  '105-6334',
  '105-6335',
  '105-6336',
  '106-0031',
  '105-0003',
  '105-0013',
  '105-6190',
  '105-6101',
  '105-6102',
  '105-6103',
  '105-6104',
  '105-6105',
  '105-6106',
  '105-6107',
  '105-6108',
  '105-6109',
  '105-6110',
  '105-6111',
  '105-6112',
  '105-6113',
  '105-6114',
  '105-6115',
  '105-6116',
  '105-6117',
  '105-6118',
  '105-6119',
  '105-6120',
  '105-6121',
  '105-6122',
  '105-6123',
  '105-6124',
  '105-6125',
  '105-6126',
  '105-6127',
  '105-6128',
  '105-6129',
  '105-6130',
  '105-6131',
  '105-6132',
  '105-6133',
  '105-6134',
  '105-6135',
  '105-6136',
  '105-6137',
  '105-6138',
  '105-6139',
  '105-6140',
  '106-0044',
  '105-0021',
  '105-7190',
  '105-7101',
  '105-7102',
  '105-7103',
  '105-7104',
  '105-7105',
  '105-7106',
  '105-7107',
  '105-7108',
  '105-7109',
  '105-7110',
  '105-7111',
  '105-7112',
  '105-7113',
  '105-7114',
  '105-7115',
  '105-7116',
  '105-7117',
  '105-7118',
  '105-7119',
  '105-7120',
  '105-7121',
  '105-7122',
  '105-7123',
  '105-7124',
  '105-7125',
  '105-7126',
  '105-7127',
  '105-7128',
  '105-7129',
  '105-7130',
  '105-7131',
  '105-7132',
  '105-7133',
  '105-7134',
  '105-7135',
  '105-7136',
  '105-7137',
  '105-7138',
  '105-7139',
  '105-7140',
  '105-7141',
  '105-7142',
  '105-7143',
  '105-7290',
  '105-7201',
  '105-7202',
  '105-7203',
  '105-7204',
  '105-7205',
  '105-7206',
  '105-7207',
  '105-7208',
  '105-7209',
  '105-7210',
  '105-7211',
  '105-7212',
  '105-7213',
  '105-7214',
  '105-7215',
  '105-7216',
  '105-7217',
  '105-7218',
  '105-7219',
  '105-7220',
  '105-7221',
  '105-7222',
  '105-7223',
  '105-7224',
  '105-7225',
  '105-7226',
  '105-7227',
  '105-7228',
  '105-7229',
  '105-7230',
  '105-7231',
  '105-7232',
  '105-7233',
  '105-7234',
  '105-7090',
  '105-7001',
  '105-7002',
  '105-7003',
  '105-7004',
  '105-7005',
  '105-7006',
  '105-7007',
  '105-7008',
  '105-7009',
  '105-7010',
  '105-7011',
  '105-7012',
  '105-7013',
  '105-7014',
  '105-7015',
  '105-7016',
  '105-7017',
  '105-7018',
  '105-7019',
  '105-7020',
  '105-7021',
  '105-7022',
  '105-7023',
  '105-7024',
  '105-7025',
  '105-7026',
  '105-7027',
  '105-7028',
  '105-7029',
  '105-7030',
  '105-7031',
  '105-7032',
  '105-7033',
  '105-7034',
  '105-7035',
  '105-7036',
  '105-7037',
  '105-7038',
  '105-7039',
  '105-7040',
  '105-7041',
  '105-7042',
  '105-7043',
  '105-7044',
  '105-7045',
  '105-7046',
  '105-7047',
  '105-7390',
  '105-7301',
  '105-7302',
  '105-7303',
  '105-7304',
  '105-7305',
  '105-7306',
  '105-7307',
  '105-7308',
  '105-7309',
  '105-7310',
  '105-7311',
  '105-7312',
  '105-7313',
  '105-7314',
  '105-7315',
  '105-7316',
  '105-7317',
  '105-7318',
  '105-7319',
  '105-7320',
  '105-7321',
  '105-7322',
  '105-7323',
  '105-7324',
  '105-7325',
  '105-7326',
  '105-7327',
  '105-7328',
  '105-7329',
  '105-7330',
  '105-7331',
  '105-7332',
  '105-7333',
  '105-7334',
  '105-7335',
  '105-7336',
  '105-7337',
  '105-7490',
  '105-7401',
  '105-7402',
  '105-7403',
  '105-7404',
  '105-7405',
  '105-7406',
  '105-7407',
  '105-7408',
  '105-7409',
  '105-7410',
  '105-7411',
  '105-7412',
  '105-7413',
  '105-7414',
  '105-7415',
  '105-7416',
  '105-7417',
  '105-7418',
  '105-7419',
  '105-7420',
  '105-7421',
  '105-7422',
  '105-7423',
  '105-7424',
  '105-7425',
  '105-7426',
  '105-7427',
  '105-7428',
  '105-7429',
  '105-7430',
  '105-7431',
  '105-7432',
  '108-0073',
  '108-6390',
  '108-6301',
  '108-6302',
  '108-6303',
  '108-6304',
  '108-6305',
  '108-6306',
  '108-6307',
  '108-6308',
  '108-6309',
  '108-6310',
  '108-6311',
  '108-6312',
  '108-6313',
  '108-6314',
  '108-6315',
  '108-6316',
  '108-6317',
  '108-6318',
  '108-6319',
  '108-6320',
  '108-6321',
  '108-6322',
  '108-6323',
  '108-6324',
  '108-6325',
  '108-6326',
  '108-6327',
  '108-6328',
  '108-6329',
  '108-6330',
  '107-0062',
  '106-0047',
  '107-0051',
  '106-0046',
  '106-0032',
  '106-6090',
  '106-6001',
  '106-6002',
  '106-6003',
  '106-6004',
  '106-6005',
  '106-6006',
  '106-6007',
  '106-6008',
  '106-6009',
  '106-6010',
  '106-6011',
  '106-6012',
  '106-6013',
  '106-6014',
  '106-6015',
  '106-6016',
  '106-6017',
  '106-6018',
  '106-6019',
  '106-6020',
  '106-6021',
  '106-6022',
  '106-6023',
  '106-6024',
  '106-6025',
  '106-6026',
  '106-6027',
  '106-6028',
  '106-6029',
  '106-6030',
  '106-6031',
  '106-6032',
  '106-6033',
  '106-6034',
  '106-6035',
  '106-6036',
  '106-6037',
  '106-6038',
  '106-6039',
  '106-6040',
  '106-6041',
  '106-6042',
  '106-6043',
  '106-6044',
  '106-6045',
  '106-6290',
  '106-6201',
  '106-6202',
  '106-6203',
  '106-6204',
  '106-6205',
  '106-6206',
  '106-6207',
  '106-6208',
  '106-6209',
  '106-6210',
  '106-6211',
  '106-6212',
  '106-6213',
  '106-6214',
  '106-6215',
  '106-6216',
  '106-6217',
  '106-6218',
  '106-6219',
  '106-6220',
  '106-6221',
  '106-6222',
  '106-6223',
  '106-6224',
  '106-6225',
  '106-6226',
  '106-6227',
  '106-6228',
  '106-6229',
  '106-6230',
  '106-6231',
  '106-6232',
  '106-6233',
  '106-6234',
  '106-6235',
  '106-6236',
  '106-6237',
  '106-6238',
  '106-6239',
  '106-6240',
  '106-6190',
  '106-6101',
  '106-6102',
  '106-6103',
  '106-6104',
  '106-6105',
  '106-6106',
  '106-6107',
  '106-6108',
  '106-6109',
  '106-6110',
  '106-6111',
  '106-6112',
  '106-6113',
  '106-6114',
  '106-6115',
  '106-6116',
  '106-6117',
  '106-6118',
  '106-6119',
  '106-6120',
  '106-6121',
  '106-6122',
  '106-6123',
  '106-6124',
  '106-6125',
  '106-6126',
  '106-6127',
  '106-6128',
  '106-6129',
  '106-6130',
  '106-6131',
  '106-6132',
  '106-6133',
  '106-6134',
  '106-6135',
  '106-6136',
  '106-6137',
  '106-6138',
  '106-6139',
  '106-6140',
  '106-6141',
  '106-6142',
  '106-6143',
  '106-6144',
  '106-6145',
  '106-6146',
  '106-6147',
  '106-6148',
  '106-6149',
  '106-6150',
  '106-6151',
  '106-6152',
  '106-6153',
  '106-6154',
  '105-0000',
  '153-0042',
  '152-0033',
  '153-0044',
  '152-0022',
  '153-0051',
  '153-0053',
  '153-0041',
  '153-0064',
  '152-0035',
  '152-0012',
  '152-0032',
  '152-0004',
  '152-0001',
  '153-0065',
  '152-0031',
  '153-0061',
  '152-0011',
  '152-0021',
  '153-0043',
  '152-0003',
  '153-0062',
  '152-0034',
  '152-0013',
  '153-0063',
  '152-0002',
  '152-0023',
  '153-0052',
  '152-0000',
  '192-0043',
  '192-0083',
  '192-0082',
  '192-0032',
  '193-0814',
  '193-0802',
  '192-0902',
  '192-0911',
  '192-0024',
  '192-0915',
  '192-0013',
  '192-0056',
  '192-0352',
  '193-0935',
  '192-0034',
  '192-0062',
  '192-0045',
  '192-0054',
  '192-0025',
  '192-0353',
  '192-0914',
  '193-0815',
  '193-0811',
  '192-0373',
  '193-0801',
  '193-0821',
  '192-0913',
  '192-0906',
  '192-0912',
  '193-0804',
  '193-0942',
  '192-0023',
  '192-0361',
  '193-0934',
  '192-0031',
  '192-0904',
  '192-0012',
  '193-0832',
  '192-0154',
  '192-0372',
  '193-0825',
  '192-0065',
  '193-0812',
  '193-0835',
  '193-0931',
  '193-0816',
  '192-0022',
  '193-0844',
  '192-0033',
  '192-0011',
  '193-0944',
  '192-0064',
  '192-0003',
  '193-0943',
  '192-0073',
  '192-0074',
  '192-0085',
  '192-0907',
  '192-0041',
  '192-0042',
  '192-0015',
  '193-0824',
  '192-0374',
  '192-0919',
  '193-0831',
  '193-0803',
  '192-0371',
  '192-0917',
  '192-0153',
  '193-0822',
  '193-0941',
  '192-0053',
  '193-0845',
  '193-0834',
  '192-0351',
  '192-0918',
  '193-0836',
  '192-0061',
  '192-0044',
  '192-0363',
  '192-0355',
  '192-0052',
  '192-0066',
  '192-0354',
  '192-0362',
  '192-0021',
  '192-0084',
  '192-0014',
  '193-0932',
  '192-0364',
  '192-0075',
  '192-0072',
  '192-0916',
  '192-0046',
  '193-0833',
  '193-0826',
  '192-0051',
  '192-0063',
  '192-0055',
  '192-0016',
  '193-0933',
  '192-0375',
  '192-0071',
  '193-0823',
  '192-0081',
  '193-0813',
  '192-0903',
  '192-0000',
  '190-0012',
  '190-0015',
  '190-0033',
  '190-0004',
  '190-0032',
  '190-0002',
  '190-0003',
  '190-0023',
  '190-0031',
  '190-0011',
  '190-0022',
  '190-0034',
  '190-0021',
  '190-0013',
  '190-0014',
  '190-0001',
  '190-000',
  '180-0004',
  '180-0002',
  '180-0003',
  '180-0001',
  '180-0023',
  '180-0005',
  '180-0022',
  '180-0021',
  '180-0014',
  '180-0006',
  '180-0013',
  '180-0012',
  '180-0011',
  '180-0000',
  '181-0011',
  '181-0001',
  '181-0015',
  '181-0012',
  '181-0003',
  '181-0013',
  '181-0004',
  '181-0016',
  '181-0005',
  '181-0014',
  '181-0002',
  '181-0000',
  '183-0003',
  '183-0012',
  '183-0021',
  '183-0041',
  '183-0056',
  '183-0013',
  '183-0014',
  '183-0054',
  '183-0051',
  '183-0015',
  '183-0011',
  '183-0052',
  '183-0034',
  '183-0001',
  '183-0002',
  '183-0053',
  '183-0043',
  '183-0046',
  '183-0031',
  '183-0044',
  '183-0036',
  '183-0016',
  '183-0057',
  '183-0024',
  '183-0055',
  '183-0033',
  '183-0032',
  '183-0027',
  '183-0006',
  '183-0026',
  '183-0022',
  '183-0023',
  '183-0045',
  '183-0042',
  '183-0004',
  '183-0025',
  '183-0035',
  '183-0005',
  '183-0000',
  '196-0001',
  '196-0000',
  '182-0004',
  '182-0035',
  '182-0007',
  '182-0022',
  '182-0026',
  '182-0016',
  '182-0014',
  '182-0034',
  '182-0012',
  '182-0013',
  '182-0011',
  '182-0017',
  '182-0002',
  '182-0023',
  '182-0025',
  '182-0021',
  '182-0036',
  '182-0006',
  '182-0032',
  '182-0031',
  '182-0005',
  '182-0033',
  '182-0024',
  '182-0001',
  '182-0015',
  '182-0003',
  '182-0000',
  '194-0211',
  '194-0023',
  '195-0062',
  '194-0003',
  '195-0064',
  '194-0215',
  '194-0204',
  '194-0212',
  '195-0072',
  '195-0071',
  '194-0012',
  '194-0015',
  '194-0201',
  '194-0037',
  '194-0036',
  '194-0033',
  '194-0014',
  '194-0202',
  '195-0057',
  '195-0051',
  '194-0203',
  '194-0035',
  '194-0041',
  '194-0001',
  '195-0061',
  '194-0004',
  '194-0213',
  '194-0021',
  '194-0044',
  '194-0011',
  '194-0043',
  '194-0046',
  '194-0038',
  '194-0034',
  '195-0053',
  '195-0063',
  '194-0013',
  '194-0042',
  '195-0056',
  '195-0052',
  '194-0032',
  '194-0031',
  '194-0002',
  '194-0045',
  '194-0005',
  '195-0054',
  '195-0055',
  '194-0022',
  '195-0073',
  '194-0214',
  '195-0075',
  '195-0074',
  '194-0000',
  '184-0002',
  '184-0005',
  '184-0001',
  '184-0012',
  '184-0014',
  '184-0015',
  '184-0011',
  '184-0004',
  '184-0013',
  '184-0003',
  '184-0000',
  '187-0001',
  '187-0031',
  '187-0035',
  '187-0032',
  '187-0043',
  '187-0045',
  '187-0044',
  '187-0034',
  '187-0023',
  '187-0022',
  '187-0021',
  '187-0011',
  '187-0024',
  '187-0025',
  '187-0004',
  '187-0033',
  '187-0042',
  '187-0002',
  '187-0003',
  '187-0041',
  '187-0012',
  '187-0013',
  '187-0000',
  '191-0065',
  '191-0022',
  '191-0021',
  '191-0061',
  '191-0034',
  '191-0014',
  '191-0015',
  '191-0001',
  '191-0063',
  '191-0023',
  '191-0002',
  '191-0016',
  '191-0031',
  '191-0062',
  '191-0051',
  '191-0053',
  '191-0055',
  '191-0052',
  '191-0054',
  '191-0012',
  '191-0003',
  '191-0011',
  '191-0043',
  '191-0064',
  '191-0042',
  '191-0024',
  '191-0032',
  '191-0041',
  '191-0013',
  '191-0033',
  '191-0000',
  '189-0002',
  '189-0001',
  '189-0011',
  '189-0003',
  '189-0013',
  '189-0021',
  '189-0026',
  '189-0022',
  '189-0012',
  '189-0024',
  '189-0014',
  '189-0023',
  '189-0025',
  '189-0000',
  '185-0024',
  '185-0001',
  '185-0004',
  '185-0036',
  '185-0003',
  '185-0033',
  '185-0005',
  '185-0013',
  '185-0035',
  '185-0023',
  '185-0014',
  '185-0002',
  '185-0022',
  '185-0034',
  '185-0032',
  '185-0031',
  '185-0011',
  '185-0012',
  '185-0021',
  '185-0000',
  '186-0013',
  '186-0014',
  '186-0012',
  '186-0001',
  '186-0004',
  '186-0005',
  '186-0002',
  '186-0003',
  '186-0015',
  '186-0011',
  '186-0000',
  '201-0003',
  '201-0015',
  '201-0005',
  '201-0004',
  '201-0016',
  '201-0012',
  '201-0011',
  '201-0001',
  '201-0014',
  '201-0002',
  '201-0013',
  '201-0000',
  '207-0033',
  '207-0023',
  '207-0011',
  '207-0002',
  '207-0022',
  '207-0003',
  '207-0004',
  '207-0012',
  '207-0032',
  '207-0005',
  '207-0021',
  '207-0001',
  '207-0015',
  '207-0016',
  '207-0031',
  '207-0014',
  '207-0013',
  '207-0000',
  '204-0002',
  '204-0024',
  '204-0013',
  '204-0001',
  '204-0011',
  '204-0023',
  '204-0012',
  '204-0003',
  '204-0004',
  '204-0022',
  '204-0021',
  '204-0000',
  '203-0001',
  '203-0021',
  '203-0003',
  '203-0051',
  '203-0052',
  '203-0043',
  '203-0013',
  '203-0002',
  '203-0012',
  '203-0011',
  '203-0033',
  '203-0054',
  '203-0041',
  '203-0042',
  '203-0014',
  '203-0004',
  '203-0022',
  '203-0053',
  '203-0032',
  '203-0023',
  '203-0031',
  '203-0044',
  '203-0034',
  '203-0000',
  '206-0041',
  '206-0002',
  '206-0033',
  '206-0015',
  '206-0012',
  '206-0035',
  '206-0014',
  '206-0013',
  '206-0042',
  '206-0024',
  '206-0011',
  '206-0034',
  '206-0031',
  '206-0036',
  '206-0025',
  '206-0003',
  '206-0022',
  '206-0023',
  '206-0032',
  '206-0004',
  '206-0021',
  '206-0001',
  '206-0000',
  '206-0801',
  '206-0811',
  '206-0803',
  '206-0822',
  '206-0821',
  '206-0802',
  '206-0823',
  '206-0804',
  '206-0812',
  '206-0824',
  '206-0000',
  '202-0011',
  '188-0003',
  '202-0003',
  '202-0006',
  '188-0014',
  '202-0004',
  '202-0023',
  '202-0005',
  '188-0011',
  '202-0013',
  '188-0004',
  '202-0012',
  '202-0021',
  '202-0001',
  '202-0002',
  '202-0014',
  '202-0015',
  '188-0002',
  '188-0012',
  '188-0013',
  '202-0022',
  '188-0001',
  '202-0000',
  '208-0023',
  '208-0000',
  '227-0062',
  '227-0066',
  '225-0011',
  '225-0012',
  '225-0024',
  '225-0002',
  '225-0001',
  '227-0052',
  '225-0005',
  '225-0013',
  '225-0014',
  '225-0015',
  '227-0063',
  '225-0023',
  '227-0065',
  '227-0048',
  '227-0034',
  '227-0041',
  '227-0033',
  '225-0025',
  '225-0022',
  '227-0061',
  '227-0053',
  '227-0031',
  '227-0042',
  '227-0054',
  '225-0003',
  '225-0021',
  '227-0035',
  '227-0046',
  '227-0064',
  '227-0051',
  '227-0055',
  '227-0038',
  '227-0036',
  '227-0032',
  '227-0043',
  '227-0067',
  '225-0016',
  '227-0047',
  '227-0037',
  '227-0044',
  '225-0004',
  '225-0026',
  '227-0045',
  '227-0000',
  '241-0014',
  '241-0033',
  '241-0817',
  '241-0813',
  '241-0032',
  '241-0031',
  '241-0034',
  '241-0834',
  '241-0015',
  '241-0835',
  '241-0812',
  '241-0802',
  '241-0002',
  '241-0001',
  '241-0804',
  '241-0803',
  '241-0011',
  '241-0832',
  '241-0831',
  '241-0816',
  '241-0822',
  '241-0013',
  '241-0025',
  '241-0806',
  '241-0005',
  '241-0003',
  '241-0823',
  '241-0805',
  '241-0022',
  '241-0021',
  '241-0815',
  '241-0825',
  '241-0814',
  '241-0004',
  '241-0012',
  '241-0826',
  '241-0821',
  '241-0023',
  '241-0024',
  '241-0836',
  '241-0824',
  '241-0833',
  '241-0811',
  '241-0801',
  '241-0000',
  '245-0001',
  '245-0022',
  '245-0024',
  '245-0023',
  '245-0016',
  '245-0003',
  '245-0007',
  '245-0018',
  '245-0017',
  '245-0021',
  '245-0005',
  '245-0009',
  '245-0011',
  '245-0013',
  '245-0015',
  '245-0014',
  '245-0012',
  '245-0006',
  '245-0008',
  '245-0004',
  '245-0002',
  '245-0000',
  '235-0016',
  '235-0019',
  '235-0006',
  '235-0021',
  '235-0001',
  '235-0042',
  '235-0041',
  '235-0003',
  '235-0022',
  '235-0004',
  '235-0017',
  '235-0032',
  '235-0031',
  '235-0018',
  '235-0033',
  '235-0034',
  '235-0012',
  '235-0035',
  '235-0014',
  '235-0036',
  '235-0007',
  '235-0002',
  '235-0008',
  '235-0005',
  '235-0015',
  '235-0043',
  '235-0013',
  '235-0011',
  '235-0044',
  '235-0023',
  '235-0024',
  '235-0045',
  '235-0000',
  '221-0057',
  '221-0814',
  '221-0032',
  '221-0842',
  '221-0014',
  '221-0062',
  '221-0042',
  '221-0024',
  '221-0002',
  '221-0003',
  '221-0055',
  '221-0865',
  '221-0045',
  '221-0046',
  '221-0831',
  '221-0011',
  '221-0015',
  '221-0041',
  '221-0801',
  '221-0832',
  '221-0056',
  '221-0804',
  '221-0051',
  '221-0012',
  '221-0021',
  '221-0811',
  '221-0052',
  '221-0844',
  '221-0862',
  '221-0075',
  '221-0071',
  '221-0072',
  '221-0074',
  '221-0073',
  '221-0077',
  '221-0076',
  '221-0031',
  '221-0013',
  '221-0043',
  '221-0864',
  '221-0033',
  '221-0834',
  '221-0833',
  '221-0023',
  '221-0063',
  '221-0825',
  '221-0036',
  '221-0835',
  '221-0821',
  '221-0064',
  '221-0803',
  '221-0061',
  '221-0004',
  '221-0822',
  '221-0001',
  '221-0813',
  '221-0065',
  '221-0863',
  '221-0866',
  '221-0053',
  '221-0044',
  '221-0812',
  '221-0824',
  '221-0823',
  '221-0035',
  '221-0843',
  '221-0005',
  '221-0841',
  '221-0034',
  '221-0856',
  '221-0851',
  '221-0852',
  '221-0853',
  '221-0855',
  '221-0854',
  '221-0022',
  '221-0054',
  '221-0802',
  '221-0000',
  '236-0034',
  '236-0013',
  '236-0043',
  '236-0024',
  '236-0055',
  '236-0015',
  '236-0041',
  '236-0042',
  '236-0046',
  '236-0045',
  '236-0003',
  '236-0012',
  '236-0001',
  '236-0007',
  '236-0028',
  '236-0027',
  '236-0035',
  '236-0044',
  '236-0021',
  '236-0014',
  '236-0051',
  '236-0052',
  '236-0002',
  '236-0011',
  '236-0005',
  '236-0017',
  '236-0057',
  '236-0053',
  '236-0058',
  '236-0056',
  '236-0025',
  '236-0006',
  '236-0033',
  '236-0023',
  '236-0004',
  '236-0054',
  '236-0022',
  '236-0036',
  '236-0031',
  '236-0037',
  '236-0038',
  '236-0032',
  '236-0016',
  '236-0026',
  '236-0000',
  '233-0007',
  '233-0001',
  '233-0002',
  '233-0012',
  '233-0014',
  '233-0003',
  '234-0054',
  '233-0004',
  '233-0008',
  '234-0052',
  '233-0016',
  '233-0006',
  '234-0056',
  '233-0005',
  '233-0011',
  '233-0015',
  '234-0051',
  '234-0053',
  '234-0055',
  '233-0013',
  '233-0000',
  '222-0037',
  '222-0003',
  '222-0004',
  '222-0011',
  '222-0034',
  '223-0059',
  '222-0036',
  '222-0024',
  '222-0026',
  '222-0025',
  '222-0022',
  '222-0021',
  '223-0064',
  '222-0033',
  '223-0056',
  '223-0058',
  '223-0063',
  '223-0065',
  '223-0066',
  '222-0001',
  '223-0055',
  '223-0054',
  '223-0052',
  '223-0053',
  '222-0035',
  '222-0023',
  '222-0013',
  '223-0057',
  '223-0061',
  '223-0062',
  '222-0012',
  '222-0032',
  '223-0051',
  '222-0002',
  '222-0000',
  '244-0842',
  '247-0026',
  '247-0027',
  '247-0006',
  '247-0009',
  '247-0003',
  '247-0034',
  '247-0032',
  '247-0035',
  '247-0033',
  '247-0031',
  '247-0005',
  '244-0845',
  '247-0013',
  '247-0025',
  '247-0028',
  '247-0014',
  '247-0007',
  '247-0001',
  '247-0002',
  '247-0022',
  '244-0844',
  '244-0843',
  '247-0023',
  '244-0841',
  '247-0015',
  '247-0024',
  '247-0004',
  '247-0021',
  '247-0008',
  '247-0011',
  '247-0012',
  '247-0000',
  '246-0013',
  '246-0023',
  '246-0025',
  '246-0026',
  '246-0012',
  '246-0011',
  '246-0001',
  '246-0006',
  '246-0036',
  '246-0002',
  '246-0008',
  '246-0035',
  '246-0031',
  '246-0003',
  '246-0005',
  '246-0014',
  '246-0004',
  '246-0037',
  '246-0021',
  '246-0015',
  '246-0022',
  '246-0034',
  '246-0032',
  '246-0038',
  '246-0007',
  '246-0000',
  '224-0016',
  '224-0053',
  '224-0012',
  '224-0011',
  '224-0014',
  '224-0015',
  '224-0006',
  '224-0007',
  '224-0004',
  '224-0008',
  '224-0042',
  '224-0027',
  '224-0028',
  '224-0061',
  '224-0043',
  '224-0055',
  '224-0034',
  '224-0036',
  '224-0044',
  '224-0056',
  '224-0057',
  '224-0021',
  '224-0062',
  '224-0054',
  '224-0046',
  '224-0035',
  '224-0013',
  '224-0065',
  '224-0032',
  '224-0031',
  '224-0033',
  '224-0037',
  '224-0001',
  '224-0003',
  '224-0063',
  '224-0041',
  '224-0052',
  '224-0025',
  '224-0045',
  '224-0023',
  '224-0024',
  '224-0064',
  '224-0051',
  '224-0029',
  '224-0026',
  '224-0066',
  '224-0000',
  '230-0033',
  '230-0035',
  '230-0021',
  '230-0024',
  '230-0022',
  '230-0023',
  '230-0026',
  '230-0025',
  '230-0041',
  '230-0002',
  '230-0055',
  '230-0046',
  '230-0072',
  '230-0011',
  '230-0075',
  '230-0034',
  '230-0078',
  '230-0074',
  '230-0071',
  '230-0038',
  '230-0043',
  '230-0073',
  '230-0047',
  '230-0003',
  '230-0012',
  '230-0045',
  '230-0027',
  '230-0014',
  '230-0053',
  '230-0054',
  '230-0032',
  '230-0061',
  '230-0063',
  '230-0051',
  '230-0015',
  '230-0062',
  '230-0042',
  '230-0052',
  '230-0076',
  '230-0036',
  '230-0077',
  '230-0017',
  '230-0018',
  '230-0016',
  '230-0031',
  '230-0044',
  '230-0048',
  '230-0013',
  '230-0037',
  '230-0004',
  '230-0001',
  '230-0000',
  '245-0052',
  '245-0064',
  '244-0812',
  '244-0811',
  '244-0816',
  '244-0806',
  '245-0053',
  '244-0805',
  '245-0061',
  '245-0062',
  '244-0004',
  '244-0801',
  '244-0815',
  '244-0003',
  '244-0001',
  '245-0051',
  '245-0063',
  '245-0065',
  '244-0802',
  '244-0803',
  '245-0067',
  '244-0813',
  '244-0804',
  '245-0066',
  '244-0814',
  '244-0002',
  '244-0817',
  '244-0000',
  '231-0012',
  '231-0051',
  '231-0057',
  '231-0834',
  '231-0868',
  '231-0045',
  '231-0842',
  '231-0867',
  '231-0061',
  '231-0027',
  '231-0858',
  '231-0011',
  '231-0859',
  '231-0028',
  '231-0015',
  '231-0002',
  '231-0866',
  '231-0813',
  '231-0865',
  '231-0003',
  '231-0054',
  '231-0026',
  '231-0802',
  '231-0848',
  '231-0062',
  '231-0001',
  '231-0801',
  '231-0046',
  '231-0055',
  '231-0013',
  '231-0863',
  '231-0837',
  '231-0847',
  '231-0845',
  '231-0035',
  '231-0815',
  '231-0033',
  '231-0864',
  '231-0857',
  '231-0855',
  '231-0014',
  '231-0814',
  '231-0839',
  '231-0812',
  '231-0852',
  '231-0844',
  '231-0021',
  '231-0854',
  '231-0835',
  '231-0853',
  '231-0836',
  '231-0064',
  '231-0047',
  '231-0053',
  '231-0063',
  '231-0052',
  '231-0031',
  '231-0066',
  '231-0043',
  '231-0044',
  '231-0042',
  '231-0037',
  '231-0032',
  '231-0007',
  '231-0048',
  '231-0843',
  '231-0005',
  '231-0826',
  '231-0823',
  '231-0824',
  '231-0803',
  '231-0821',
  '231-0811',
  '231-0825',
  '231-0833',
  '231-0832',
  '231-0804',
  '231-0822',
  '231-0827',
  '231-0806',
  '231-0016',
  '231-0025',
  '231-0838',
  '231-0017',
  '231-0006',
  '231-0816',
  '231-0856',
  '231-0065',
  '231-0841',
  '231-0034',
  '231-0849',
  '231-0004',
  '231-0861',
  '231-0831',
  '231-0023',
  '231-0036',
  '231-0862',
  '231-0846',
  '231-0038',
  '231-0851',
  '231-0058',
  '231-0022',
  '231-0041',
  '231-0024',
  '231-0056',
  '231-0805',
  '231-0000',
  '220-0034',
  '220-0033',
  '220-0045',
  '220-0032',
  '220-0073',
  '220-0035',
  '220-0001',
  '220-0004',
  '220-0003',
  '220-0061',
  '220-0043',
  '220-0054',
  '220-0021',
  '220-0071',
  '220-0072',
  '220-0011',
  '220-0051',
  '220-0042',
  '220-0041',
  '220-0046',
  '220-0024',
  '220-0052',
  '220-0022',
  '220-0055',
  '220-0062',
  '220-0023',
  '220-0053',
  '220-0013',
  '220-0012',
  '220-6090',
  '220-6001',
  '220-6002',
  '220-6003',
  '220-6004',
  '220-6005',
  '220-6006',
  '220-6007',
  '220-6008',
  '220-6009',
  '220-6010',
  '220-6011',
  '220-6012',
  '220-6013',
  '220-6014',
  '220-6015',
  '220-6016',
  '220-6017',
  '220-6018',
  '220-6019',
  '220-6020',
  '220-6021',
  '220-6022',
  '220-6023',
  '220-6024',
  '220-6025',
  '220-6026',
  '220-6027',
  '220-6028',
  '220-6029',
  '220-6030',
  '220-6031',
  '220-6032',
  '220-6033',
  '220-6034',
  '220-6035',
  '220-6190',
  '220-6101',
  '220-6102',
  '220-6103',
  '220-6104',
  '220-6105',
  '220-6106',
  '220-6107',
  '220-6108',
  '220-6109',
  '220-6110',
  '220-6111',
  '220-6112',
  '220-6113',
  '220-6114',
  '220-6115',
  '220-6116',
  '220-6117',
  '220-6118',
  '220-6119',
  '220-6120',
  '220-6121',
  '220-6122',
  '220-6123',
  '220-6124',
  '220-6125',
  '220-6126',
  '220-6127',
  '220-6128',
  '220-6290',
  '220-6201',
  '220-6202',
  '220-6203',
  '220-6204',
  '220-6205',
  '220-6206',
  '220-6207',
  '220-6208',
  '220-6209',
  '220-6210',
  '220-6211',
  '220-6212',
  '220-6213',
  '220-6214',
  '220-6215',
  '220-6216',
  '220-6217',
  '220-6218',
  '220-6219',
  '220-6220',
  '220-6221',
  '220-8190',
  '220-8101',
  '220-8102',
  '220-8103',
  '220-8104',
  '220-8105',
  '220-8106',
  '220-8107',
  '220-8108',
  '220-8109',
  '220-8110',
  '220-8111',
  '220-8112',
  '220-8113',
  '220-8114',
  '220-8115',
  '220-8116',
  '220-8117',
  '220-8118',
  '220-8119',
  '220-8120',
  '220-8121',
  '220-8122',
  '220-8123',
  '220-8124',
  '220-8125',
  '220-8126',
  '220-8127',
  '220-8128',
  '220-8129',
  '220-8130',
  '220-8131',
  '220-8132',
  '220-8133',
  '220-8134',
  '220-8135',
  '220-8136',
  '220-8137',
  '220-8138',
  '220-8139',
  '220-8140',
  '220-8141',
  '220-8142',
  '220-8143',
  '220-8144',
  '220-8145',
  '220-8146',
  '220-8147',
  '220-8148',
  '220-8149',
  '220-8150',
  '220-8151',
  '220-8152',
  '220-8153',
  '220-8154',
  '220-8155',
  '220-8156',
  '220-8157',
  '220-8158',
  '220-8159',
  '220-8160',
  '220-8161',
  '220-8162',
  '220-8163',
  '220-8164',
  '220-8165',
  '220-8166',
  '220-8167',
  '220-8168',
  '220-8169',
  '220-8170',
  '220-0002',
  '220-0005',
  '220-0074',
  '220-0006',
  '220-0031',
  '220-0063',
  '220-0044',
  '220-0000',
  '240-0053',
  '240-0035',
  '240-0023',
  '240-0015',
  '240-0004',
  '240-0062',
  '240-0014',
  '240-0013',
  '240-0066',
  '240-0063',
  '240-0051',
  '240-0042',
  '240-0025',
  '240-0045',
  '240-0001',
  '240-0005',
  '240-0026',
  '240-0034',
  '240-0033',
  '240-0043',
  '240-0011',
  '240-0036',
  '240-0024',
  '240-0012',
  '240-0003',
  '240-0067',
  '240-0022',
  '240-0052',
  '240-0016',
  '240-0017',
  '240-0041',
  '240-0031',
  '240-0044',
  '240-0046',
  '240-0032',
  '240-0006',
  '240-0021',
  '240-0064',
  '240-0061',
  '240-0002',
  '240-0007',
  '240-0065',
  '240-0000',
  '226-0022',
  '226-0028',
  '226-0012',
  '226-0003',
  '226-0004',
  '226-0021',
  '226-0016',
  '226-0023',
  '226-0014',
  '226-0005',
  '226-0013',
  '226-0025',
  '226-0027',
  '226-0026',
  '226-0018',
  '226-0019',
  '226-0011',
  '226-0017',
  '226-0024',
  '226-0006',
  '226-0002',
  '226-0001',
  '226-0015',
  '226-0029',
  '226-0000',
  '232-0051',
  '232-0052',
  '232-0053',
  '232-0024',
  '232-0031',
  '232-0044',
  '232-0061',
  '232-0054',
  '232-0008',
  '232-0034',
  '232-0015',
  '232-0067',
  '232-0013',
  '232-0036',
  '232-0007',
  '232-0017',
  '232-0005',
  '232-0023',
  '232-0027',
  '232-0025',
  '232-0022',
  '232-0056',
  '232-0063',
  '232-0062',
  '232-0055',
  '232-0074',
  '232-0076',
  '232-0072',
  '232-0073',
  '232-0071',
  '232-0075',
  '232-0033',
  '232-0003',
  '232-0037',
  '232-0018',
  '232-0011',
  '232-0045',
  '232-0001',
  '232-0026',
  '232-0035',
  '232-0064',
  '232-0065',
  '232-0042',
  '232-0043',
  '232-0004',
  '232-0021',
  '232-0032',
  '232-0006',
  '232-0012',
  '232-0002',
  '232-0016',
  '232-0066',
  '232-0041',
  '232-0014',
  '232-0057',
  '232-0000',
  '215-0013',
  '215-0018',
  '215-0017',
  '215-0027',
  '215-0023',
  '215-0006',
  '215-0021',
  '215-0033',
  '215-0032',
  '215-0031',
  '215-0035',
  '215-0025',
  '215-0022',
  '215-0024',
  '215-0003',
  '215-0002',
  '215-0005',
  '215-0015',
  '215-0014',
  '215-0016',
  '215-0036',
  '215-0012',
  '215-0026',
  '215-0001',
  '215-0004',
  '215-0034',
  '215-0007',
  '215-0011',
  '215-0000',
  '210-0847',
  '210-0854',
  '210-0808',
  '210-0832',
  '210-0864',
  '210-0022',
  '210-0006',
  '210-0805',
  '210-0862',
  '210-0823',
  '210-0007',
  '210-0002',
  '210-0835',
  '210-0867',
  '210-0858',
  '210-0868',
  '210-0834',
  '210-0836',
  '210-0023',
  '210-0846',
  '210-0843',
  '210-0014',
  '210-0803',
  '210-0831',
  '210-0848',
  '210-0852',
  '210-0861',
  '210-0838',
  '210-0833',
  '210-0826',
  '210-0025',
  '210-0813',
  '210-0857',
  '210-0013',
  '210-0801',
  '210-0802',
  '210-0811',
  '210-0815',
  '210-0817',
  '210-0816',
  '210-0814',
  '210-0853',
  '210-0856',
  '210-0822',
  '210-0865',
  '210-0026',
  '210-0825',
  '210-0821',
  '210-0806',
  '210-0818',
  '210-0024',
  '210-0851',
  '210-0869',
  '210-0005',
  '210-0812',
  '210-0824',
  '210-0804',
  '210-0011',
  '210-0003',
  '210-0001',
  '210-0866',
  '210-0807',
  '210-0015',
  '210-0855',
  '210-0012',
  '210-0004',
  '210-0021',
  '210-0863',
  '210-0828',
  '210-0827',
  '210-0837',
  '210-0845',
  '210-0844',
  '210-0842',
  '210-0841',
  '210-0000',
  '212-0006',
  '212-0014',
  '212-0054',
  '212-0058',
  '212-0007',
  '212-0057',
  '212-0003',
  '212-0001',
  '212-0002',
  '212-0004',
  '212-0026',
  '212-0011',
  '212-0053',
  '212-0031',
  '212-0032',
  '212-0027',
  '212-0022',
  '212-0024',
  '212-0005',
  '212-0023',
  '212-0012',
  '212-0033',
  '212-0051',
  '212-0052',
  '212-0025',
  '212-0013',
  '212-0055',
  '212-0016',
  '212-0021',
  '212-0056',
  '212-0015',
  '212-0000',
  '213-0024',
  '213-0031',
  '213-0015',
  '213-0025',
  '213-0034',
  '213-0005',
  '213-0032',
  '213-0012',
  '213-0023',
  '213-0033',
  '213-0006',
  '213-0014',
  '213-0013',
  '213-0004',
  '213-0003',
  '213-0022',
  '213-0021',
  '213-0027',
  '213-0026',
  '213-0011',
  '213-0002',
  '213-0001',
  '213-0035',
  '213-0000',
  '214-0038',
  '214-0039',
  '214-0021',
  '214-0001',
  '214-0003',
  '214-0008',
  '214-0007',
  '214-0006',
  '214-0002',
  '214-0004',
  '214-0022',
  '214-0005',
  '214-0023',
  '214-0035',
  '214-0012',
  '214-0037',
  '214-0014',
  '214-0013',
  '214-0031',
  '214-0033',
  '214-0011',
  '214-0032',
  '214-0034',
  '214-0036',
  '214-0000',
  '211-0035',
  '211-0037',
  '211-0036',
  '211-0034',
  '211-0016',
  '211-0067',
  '211-0065',
  '211-0066',
  '211-0064',
  '211-0023',
  '211-0053',
  '211-0045',
  '211-0013',
  '211-0003',
  '211-0002',
  '211-0007',
  '211-0001',
  '211-0022',
  '211-0015',
  '211-0025',
  '211-0032',
  '211-0031',
  '211-0033',
  '211-0021',
  '211-0061',
  '211-0068',
  '211-0062',
  '211-0063',
  '211-0041',
  '211-0042',
  '211-0011',
  '211-0044',
  '211-0043',
  '211-0004',
  '211-0005',
  '211-0014',
  '211-0052',
  '211-0012',
  '211-0024',
  '211-0006',
  '211-0051',
  '211-0000',
  '216-0003',
  '216-0011',
  '216-0034',
  '216-0023',
  '216-0021',
  '216-0007',
  '216-0004',
  '216-0013',
  '216-0032',
  '216-0031',
  '216-0025',
  '216-0015',
  '216-0014',
  '216-0022',
  '216-0005',
  '216-0024',
  '216-0001',
  '216-0026',
  '216-0002',
  '216-0035',
  '216-0012',
  '216-0033',
  '216-0006',
  '216-0000',
  '240-0105',
  '238-0033',
  '239-0845',
  '238-0048',
  '238-0035',
  '239-0806',
  '238-0003',
  '238-0051',
  '239-0844',
  '239-0836',
  '239-0837',
  '239-0822',
  '239-0823',
  '239-0815',
  '237-0062',
  '238-0017',
  '238-0008',
  '238-0311',
  '239-0808',
  '238-0024',
  '238-0004',
  '237-0064',
  '237-0063',
  '237-0065',
  '237-0068',
  '239-0812',
  '238-0034',
  '239-0813',
  '238-0031',
  '238-0025',
  '238-0022',
  '238-0002',
  '239-0828',
  '239-0834',
  '239-0846',
  '239-0831',
  '239-0827',
  '239-0820',
  '240-0106',
  '238-0026',
  '238-0043',
  '239-0803',
  '238-0052',
  '239-0835',
  '238-0042',
  '238-0054',
  '240-0107',
  '237-0066',
  '238-0005',
  '239-0832',
  '237-0071',
  '237-0075',
  '237-0067',
  '238-0313',
  '238-0015',
  '239-0843',
  '238-0056',
  '238-0001',
  '237-0072',
  '240-0101',
  '239-0842',
  '239-0826',
  '237-0061',
  '239-0824',
  '238-0046',
  '239-0807',
  '239-0841',
  '239-0833',
  '237-0078',
  '239-0811',
  '237-0077',
  '238-0315',
  '239-0821',
  '238-0045',
  '239-0847',
  '238-0006',
  '238-0032',
  '238-0016',
  '238-0021',
  '239-0814',
  '239-0805',
  '237-0076',
  '238-0013',
  '238-0055',
  '238-0044',
  '238-0053',
  '238-0041',
  '239-0801',
  '239-0802',
  '238-0018',
  '237-0079',
  '239-0825',
  '238-0014',
  '238-0023',
  '238-0012',
  '238-0312',
  '239-0804',
  '238-0047',
  '238-0011',
  '238-0007',
  '239-0829',
  '238-0000',
  '254-0042',
  '254-0031',
  '254-0915',
  '254-0025',
  '254-0033',
  '254-0047',
  '254-0012',
  '254-0074',
  '259-1213',
  '259-1204',
  '254-0055',
  '259-1207',
  '254-0906',
  '254-0801',
  '254-0821',
  '254-0903',
  '254-0061',
  '254-0804',
  '254-0053',
  '259-1206',
  '254-0014',
  '254-0003',
  '254-0076',
  '254-0022',
  '254-0822',
  '254-0063',
  '254-0041',
  '254-0803',
  '259-1202',
  '254-0813',
  '254-0807',
  '254-0912',
  '254-0805',
  '254-0914',
  '254-0034',
  '254-0046',
  '254-0013',
  '254-0064',
  '259-1205',
  '254-0027',
  '254-0826',
  '254-0902',
  '254-0081',
  '254-0051',
  '254-0054',
  '254-0026',
  '254-0021',
  '254-0075',
  '254-0825',
  '254-0823',
  '254-0044',
  '254-0019',
  '254-0073',
  '254-0904',
  '254-0824',
  '254-0023',
  '254-0024',
  '254-0018',
  '254-0082',
  '254-0077',
  '254-0016',
  '254-0905',
  '254-0052',
  '254-0062',
  '254-0802',
  '254-0043',
  '254-0812',
  '254-0901',
  '254-0913',
  '254-0045',
  '259-1201',
  '254-0065',
  '254-0035',
  '254-0036',
  '259-1220',
  '254-0815',
  '254-0811',
  '254-0032',
  '254-0911',
  '254-0015',
  '254-0806',
  '254-0002',
  '254-0814',
  '254-0000',
  '248-0024',
  '247-0052',
  '247-0053',
  '247-0051',
  '247-0073',
  '248-0011',
  '247-0056',
  '248-0007',
  '247-0072',
  '248-0012',
  '247-0063',
  '248-0031',
  '247-0065',
  '248-0023',
  '248-0033',
  '247-0055',
  '248-0006',
  '248-0013',
  '248-0021',
  '248-0015',
  '248-0017',
  '248-0026',
  '248-0025',
  '248-0001',
  '248-0003',
  '247-0074',
  '247-0075',
  '247-0061',
  '247-0054',
  '247-0071',
  '248-0032',
  '248-0034',
  '248-0036',
  '247-0064',
  '248-0022',
  '248-0002',
  '248-0035',
  '248-0004',
  '248-0016',
  '248-0027',
  '247-0066',
  '247-0062',
  '248-0014',
  '248-0005',
  '248-0000',
  '251-0054',
  '252-0815',
  '251-0862',
  '252-0803',
  '252-0824',
  '251-0036',
  '252-0805',
  '252-0816',
  '251-0861',
  '251-0032',
  '251-0035',
  '251-0034',
  '251-0033',
  '252-0813',
  '251-0003',
  '251-0015',
  '252-0811',
  '251-0022',
  '251-0025',
  '251-0037',
  '251-0027',
  '251-0021',
  '251-0024',
  '251-0023',
  '251-0026',
  '251-0031',
  '251-0038',
  '252-0822',
  '251-0013',
  '252-0807',
  '251-0057',
  '252-0804',
  '252-0823',
  '251-0051',
  '251-0871',
  '251-0876',
  '251-0877',
  '251-0002',
  '252-0802',
  '251-0017',
  '251-0872',
  '252-0801',
  '251-0047',
  '251-0041',
  '251-0042',
  '251-0044',
  '251-0045',
  '251-0046',
  '251-0043',
  '252-0806',
  '252-0814',
  '251-0001',
  '252-0812',
  '251-0056',
  '251-0874',
  '251-0004',
  '251-0052',
  '251-0028',
  '251-0053',
  '251-0875',
  '251-0873',
  '251-0055',
  '251-0014',
  '251-0016',
  '251-0012',
  '252-0821',
  '251-0011',
  '251-0000',
  '253-0001',
  '253-0013',
  '253-0026',
  '253-0004',
  '253-0072',
  '253-0084',
  '253-0082',
  '253-0016',
  '253-0012',
  '253-0052',
  '253-0033',
  '253-0081',
  '253-0087',
  '253-0045',
  '253-0017',
  '253-0036',
  '253-0044',
  '253-0008',
  '253-0015',
  '253-0002',
  '253-0041',
  '253-0006',
  '253-0003',
  '253-0028',
  '253-0032',
  '253-0056',
  '253-0055',
  '253-0073',
  '253-0007',
  '253-0061',
  '253-0083',
  '253-0071',
  '253-0035',
  '253-0021',
  '253-0086',
  '253-0062',
  '253-0054',
  '253-0053',
  '253-0011',
  '253-0037',
  '253-0027',
  '253-0031',
  '253-0074',
  '253-0024',
  '253-0014',
  '253-0042',
  '253-0065',
  '253-0025',
  '253-0005',
  '253-0022',
  '253-0088',
  '253-0023',
  '253-0034',
  '253-0018',
  '253-0043',
  '253-0064',
  '253-0063',
  '253-0085',
  '253-0051',
  '253-0000',
  '249-0003',
  '249-0008',
  '249-0005',
  '249-0007',
  '249-0006',
  '249-0004',
  '249-0001',
  '249-0002',
  '249-0000',
  '252-0235',
  '252-0224',
  '252-0251',
  '252-0205',
  '252-0233',
  '252-0243',
  '252-0201',
  '252-0234',
  '252-0214',
  '252-0217',
  '252-0231',
  '252-0254',
  '252-0213',
  '252-0216',
  '252-0221',
  '252-0244',
  '252-0245',
  '252-0239',
  '252-0237',
  '252-0228',
  '252-0203',
  '252-0227',
  '252-0215',
  '252-0236',
  '252-0206',
  '252-0202',
  '252-0238',
  '252-0223',
  '252-0225',
  '252-0253',
  '252-0212',
  '252-0211',
  '252-0229',
  '252-0232',
  '252-0207',
  '252-0208',
  '252-0226',
  '252-0242',
  '252-0241',
  '252-0222',
  '252-0200',
  '252-0141',
  '252-0135',
  '252-0146',
  '252-0136',
  '252-0111',
  '252-0105',
  '252-0134',
  '252-0124',
  '252-0131',
  '252-0137',
  '252-0143',
  '252-0132',
  '252-0102',
  '252-0103',
  '252-0144',
  '252-0101',
  '252-0104',
  '252-0142',
  '252-0100',
  '252-0304',
  '252-0328',
  '252-0325',
  '252-0301',
  '252-0331',
  '252-0302',
  '252-0318',
  '252-0329',
  '252-0344',
  '252-0306',
  '252-0303',
  '252-0321',
  '252-0322',
  '252-0315',
  '252-0335',
  '252-0326',
  '252-0312',
  '252-0324',
  '252-0323',
  '252-0336',
  '252-0332',
  '252-0333',
  '252-0311',
  '252-0316',
  '252-0307',
  '252-0313',
  '252-0317',
  '252-0314',
  '252-0305',
  '252-0334',
  '252-0300',
  '238-0113',
  '238-0111',
  '238-0101',
  '238-0200',
  '242-0029',
  '242-0014',
  '242-0028',
  '242-0023',
  '242-0027',
  '242-0001',
  '242-0015',
  '242-0026',
  '242-0025',
  '242-0021',
  '242-0007',
  '242-0008',
  '242-0002',
  '242-0004',
  '242-0005',
  '242-0011',
  '242-0013',
  '242-0012',
  '242-0018',
  '242-0024',
  '242-0006',
  '242-0022',
  '242-0017',
  '242-0016',
  '242-0003',
  '242-0000',
  '243-0437',
  '243-0423',
  '243-0436',
  '243-0411',
  '243-0419',
  '243-0418',
  '243-0402',
  '243-0404',
  '243-0431',
  '243-0434',
  '243-0415',
  '243-0433',
  '243-0413',
  '243-0405',
  '243-0406',
  '243-0421',
  '243-0435',
  '243-0424',
  '243-0414',
  '243-0410',
  '243-0427',
  '243-0432',
  '243-0425',
  '243-0412',
  '243-0401',
  '243-0417',
  '243-0438',
  '243-0403',
  '243-0400',
  '252-0024',
  '252-0013',
  '252-0014',
  '252-0002',
  '252-0001',
  '252-0005',
  '252-0011',
  '252-0023',
  '252-0016',
  '252-0004',
  '252-0003',
  '252-0012',
  '252-0021',
  '252-0015',
  '252-0000',
  '252-1104',
  '252-1115',
  '252-1116',
  '252-1113',
  '252-1114',
  '252-1111',
  '252-1121',
  '252-1122',
  '252-1105',
  '252-1135',
  '252-1132',
  '252-1136',
  '252-1134',
  '252-1131',
  '252-1137',
  '252-1133',
  '252-1123',
  '252-1127',
  '252-1103',
  '252-1108',
  '252-1107',
  '252-1106',
  '252-1102',
  '252-1124',
  '252-1125',
  '252-1126',
  '252-1100',
  '240-0111',
  '240-0115',
  '240-0116',
  '240-0113',
  '240-0112',
  '240-0100',
  '253-0111',
  '253-0104',
  '253-0113',
  '253-0105',
  '253-0101',
  '253-0103',
  '253-0102',
  '253-0114',
  '253-0112',
  '253-0106',
  '253-0100',
  '255-0003',
  '255-0001',
  '255-0002',
  '255-0000',
  '259-0151',
  '259-0154',
  '259-0157',
  '259-0152',
  '259-0100',
  '258-0023',
  '258-0000',
  '250-0111',
  '250-0100',
  '292-0055',
  '292-0065',
  '292-0026',
  '292-0034',
  '292-0061',
  '292-0033',
  '292-0001',
  '292-0012',
  '292-0007',
  '292-0063',
  '292-0815',
  '292-0044',
  '292-0833',
  '292-0818',
  '292-0009',
  '292-0813',
  '292-0052',
  '292-0056',
  '292-0051',
  '292-0045',
  '292-0041',
  '292-0042',
  '292-0811',
  '292-0004',
  '292-0005',
  '292-0827',
  '292-0824',
  '292-0803',
  '299-0271',
  '292-0822',
  '292-0823',
  '292-0821',
  '292-0032',
  '292-0838',
  '292-0834',
  '292-0816',
  '292-0801',
  '292-0806',
  '292-0807',
  '292-0066',
  '292-0832',
  '292-0036',
  '292-0027',
  '292-0016',
  '292-0014',
  '292-0067',
  '292-0835',
  '292-0053',
  '292-0035',
  '292-0817',
  '292-0064',
  '292-0008',
  '292-0054',
  '292-0002',
  '292-0062',
  '292-0825',
  '292-0826',
  '292-0814',
  '292-0819',
  '292-0043',
  '292-0057',
  '292-0037',
  '292-0831',
  '292-0804',
  '292-0038',
  '292-0015',
  '292-0802',
  '292-0003',
  '292-0812',
  '292-0805',
  '292-0013',
  '292-0000',
  '276-0045',
  '276-0024',
  '276-0023',
  '276-0020',
  '276-0025',
  '276-0022',
  '276-0044',
  '276-0026',
  '276-0035',
  '276-0036',
  '276-0037',
  '276-0027',
  '276-0028',
  '276-0032',
  '276-0034',
  '276-0033',
  '276-0031',
  '276-0000',
  '299-1175',
  '299-1146',
  '292-1149',
  '299-1164',
  '299-1161',
  '299-1141',
  '299-1143',
  '299-1152',
  '299-1165',
  '299-1127',
  '299-1142',
  '299-1135',
  '299-1173',
  '299-1153',
  '299-1121',
  '299-1132',
  '299-1151',
  '299-1145',
  '299-1122',
  '299-1144',
  '299-1147',
  '299-1174',
  '299-1154',
  '299-1162',
  '299-1172',
  '299-1117',
  '299-1163',
  '299-1171',
  '299-1166',
  '299-1100',
  '293-0012',
  '293-0001',
  '293-0005',
  '293-0024',
  '293-0006',
  '293-0011',
  '293-0013',
  '293-0002',
  '293-0000',
  '299-0256',
  '299-0264',
  '299-0253',
  '299-0257',
  '299-0266',
  '299-0242',
  '299-0243',
  '299-0245',
  '299-0262',
  '299-0247',
  '299-0241',
  '299-0265',
  '299-0246',
  '299-0267',
  '299-0263',
  '299-0251',
  '299-0244',
  '299-0261',
  '299-0254',
  '299-0268',
  '299-0200',
  '289-1114',
  '289-1107',
  '289-1133',
  '289-1100',
  '285-0852',
  '285-0064',
  '285-0832',
  '285-0032',
  '285-0031',
  '285-0813',
  '285-0864',
  '285-0855',
  '285-0856',
  '285-0071',
  '285-0004',
  '285-0863',
  '285-0861',
  '285-0866',
  '285-0077',
  '285-0024',
  '285-0824',
  '285-0823',
  '285-0825',
  '285-0837',
  '285-0817',
  '285-0802',
  '285-0806',
  '285-0043',
  '285-0808',
  '285-0853',
  '285-0836',
  '285-0811',
  '285-0013',
  '285-0821',
  '285-0026',
  '285-0025',
  '285-0846',
  '285-0044',
  '285-0801',
  '285-0803',
  '285-0805',
  '285-0014',
  '285-0807',
  '285-0006',
  '285-0035',
  '285-0815',
  '285-0854',
  '285-0017',
  '285-0045',
  '285-0862',
  '285-0023',
  '285-0034',
  '285-0831',
  '285-0046',
  '285-0012',
  '285-0818',
  '285-0819',
  '285-0816',
  '285-0065',
  '285-0022',
  '285-0033',
  '285-0015',
  '285-0845',
  '285-0074',
  '285-0850',
  '285-0867',
  '285-0814',
  '285-0036',
  '285-0042',
  '285-0865',
  '285-0075',
  '285-0016',
  '285-0857',
  '285-0005',
  '285-0063',
  '285-0038',
  '285-0812',
  '285-0021',
  '285-0037',
  '285-0039',
  '285-0011',
  '285-0858',
  '285-0834',
  '285-0000',
  '270-1351',
  '270-1353',
  '270-1360',
  '270-1347',
  '270-1364',
  '270-1352',
  '270-1327',
  '270-1358',
  '270-1356',
  '270-1363',
  '270-1369',
  '270-1613',
  '270-1359',
  '270-1343',
  '270-2326',
  '270-1366',
  '270-1614',
  '270-1337',
  '270-1300',
  '270-1333',
  '270-1342',
  '270-2328',
  '270-2329',
  '270-1346',
  '270-1340',
  '270-1350',
  '270-1611',
  '270-1618',
  '270-2325',
  '270-1348',
  '270-1349',
  '270-1334',
  '270-1601',
  '270-1335',
  '270-1341',
  '270-1336',
  '270-1605',
  '270-1606',
  '270-1345',
  '270-1332',
  '270-1608',
  '270-1357',
  '270-1339',
  '270-1331',
  '270-1344',
  '270-1338',
  '270-1607',
  '270-2331',
  '270-1354',
  '270-1355',
  '270-2327',
  '270-1609',
  '270-1300',
  '270-1415',
  '270-1412',
  '270-1411',
  '270-1413',
  '270-1414',
  '270-1400',
  '286-0017',
  '286-0025',
  '286-0018',
  '286-0041',
  '286-0046',
  '286-0035',
  '286-0029',
  '286-0047',
  '286-0122',
  '286-0043',
  '286-0131',
  '286-0845',
  '286-0032',
  '286-0036',
  '286-0125',
  '282-0005',
  '286-0105',
  '286-0126',
  '286-0823',
  '286-0048',
  '286-0014',
  '286-0137',
  '282-0027',
  '286-0127',
  '282-0021',
  '286-0121',
  '286-0028',
  '282-0011',
  '286-0111',
  '286-0116',
  '286-0117',
  '286-0005',
  '286-0825',
  '286-0123',
  '286-0031',
  '286-0004',
  '286-0003',
  '286-0024',
  '286-0011',
  '286-0021',
  '286-0022',
  '282-0002',
  '286-0102',
  '282-0001',
  '282-0003',
  '286-0103',
  '286-0134',
  '282-0006',
  '286-0106',
  '286-0015',
  '286-0804',
  '286-0027',
  '286-0045',
  '286-0023',
  '286-0042',
  '286-0115',
  '286-0037',
  '286-0124',
  '286-0033',
  '286-0007',
  '286-0132',
  '282-0012',
  '286-0112',
  '286-0044',
  '286-0001',
  '282-0004',
  '286-0104',
  '282-0007',
  '286-0107',
  '286-0118',
  '286-0114',
  '286-0026',
  '286-0034',
  '286-0846',
  '286-0013',
  '286-0113',
  '286-0805',
  '286-0002',
  '286-0012',
  '286-0135',
  '286-0133',
  '286-0016',
  '286-0000',
  '286-0216',
  '286-0211',
  '286-0223',
  '286-0214',
  '286-0215',
  '286-0212',
  '286-0222',
  '286-0221',
  '286-0205',
  '286-0202',
  '286-0201',
  '286-0225',
  '286-0200',
  '285-0912',
  '285-0911',
  '285-0905',
  '285-0927',
  '285-0907',
  '285-0924',
  '285-0913',
  '285-0922',
  '285-0921',
  '285-0923',
  '285-0928',
  '285-0900',
  '282-0035',
  '289-1608',
  '282-0034',
  '289-1603',
  '289-1605',
  '282-0032',
  '289-1601',
  '282-0033',
  '289-1602',
  '289-1600',
  '289-2247',
  '289-2200',
  '270-1501',
  '270-1500',
  '339-0027',
  '339-0026',
  '339-0024',
  '339-0025',
  '339-0077',
  '339-0000',
  '330-0046',
  '330-0071',
  '330-0042',
  '330-0064',
  '330-0074',
  '330-0045',
  '330-0051',
  '330-0065',
  '330-0044',
  '330-0043',
  '330-0063',
  '330-0061',
  '330-0062',
  '330-0075',
  '330-0054',
  '330-0055',
  '330-0056',
  '330-0053',
  '330-0073',
  '330-0052',
  '330-0072',
  '330-0000',
  '330-0841',
  '330-0834',
  '330-0852',
  '330-0836',
  '330-0855',
  '330-0843',
  '330-0835',
  '330-0851',
  '330-0854',
  '330-0844',
  '330-0805',
  '330-0842',
  '330-0846',
  '330-0803',
  '330-0801',
  '330-0845',
  '330-0853',
  '330-0804',
  '330-0856',
  '330-0802',
  '330-0800',
  '331-0813',
  '331-0815',
  '331-0824',
  '331-0825',
  '331-0801',
  '331-0804',
  '331-0822',
  '331-0823',
  '331-0814',
  '331-0821',
  '331-0802',
  '331-0805',
  '331-0803',
  '331-0812',
  '331-0811',
  '331-0800',
  '338-0826',
  '338-0824',
  '338-0815',
  '338-0813',
  '338-0823',
  '338-0833',
  '338-0834',
  '338-0825',
  '338-0811',
  '338-0812',
  '338-0837',
  '338-0835',
  '338-0822',
  '338-0832',
  '338-0836',
  '338-0831',
  '338-0821',
  '338-0800',
  '338-0014',
  '338-0007',
  '338-0012',
  '338-0001',
  '338-0005',
  '338-0002',
  '330-0081',
  '330-6090',
  '330-6001',
  '330-6002',
  '330-6003',
  '330-6004',
  '330-6005',
  '330-6006',
  '330-6007',
  '330-6008',
  '330-6009',
  '330-6010',
  '330-6011',
  '330-6012',
  '330-6013',
  '330-6014',
  '330-6015',
  '330-6016',
  '330-6017',
  '330-6018',
  '330-6019',
  '330-6020',
  '330-6021',
  '330-6022',
  '330-6023',
  '330-6024',
  '330-6025',
  '330-6026',
  '330-6027',
  '330-6028',
  '330-6029',
  '330-6030',
  '330-6031',
  '330-6032',
  '330-6033',
  '330-6034',
  '330-6035',
  '338-0011',
  '338-0013',
  '338-0006',
  '338-0003',
  '338-0004',
  '330-0000',
  '331-0058',
  '331-0053',
  '331-0045',
  '331-0047',
  '331-0076',
  '331-0073',
  '331-0064',
  '331-0056',
  '331-0054',
  '331-0048',
  '331-0071',
  '331-0062',
  '331-0077',
  '331-0057',
  '331-0061',
  '331-0072',
  '331-0065',
  '331-0063',
  '331-0074',
  '331-0059',
  '331-0075',
  '331-0052',
  '331-0046',
  '331-0000',
  '336-0974',
  '336-0922',
  '336-0923',
  '336-0934',
  '336-0977',
  '336-0966',
  '336-0961',
  '336-0907',
  '336-0917',
  '336-0962',
  '336-0913',
  '336-0936',
  '336-0905',
  '336-0963',
  '336-0971',
  '336-0976',
  '336-0932',
  '336-0972',
  '336-0973',
  '336-0931',
  '336-0912',
  '336-0926',
  '336-0964',
  '336-0918',
  '336-0965',
  '336-0906',
  '336-0915',
  '336-0911',
  '336-0916',
  '336-0903',
  '336-0900',
  '336-0034',
  '336-0043',
  '336-0042',
  '336-0016',
  '336-0031',
  '336-0022',
  '336-0023',
  '336-0038',
  '336-0015',
  '336-0026',
  '336-0027',
  '336-0024',
  '336-0041',
  '336-0025',
  '336-0021',
  '336-0033',
  '336-0035',
  '336-0017',
  '336-0018',
  '336-0032',
  '336-0000',
  '337-0014',
  '337-0053',
  '337-0024',
  '337-0044',
  '337-0005',
  '337-0031',
  '337-0006',
  '337-0045',
  '337-0054',
  '337-0026',
  '337-0043',
  '337-0013',
  '337-0034',
  '337-0015',
  '337-0008',
  '337-0002',
  '337-0032',
  '337-0051',
  '337-0012',
  '337-0016',
  '337-0021',
  '337-0003',
  '337-0017',
  '337-0052',
  '337-0001',
  '337-0007',
  '337-0033',
  '337-0042',
  '337-0041',
  '337-0011',
  '337-0025',
  '337-0000',
  '332-0031',
  '334-0073',
  '333-0825',
  '332-0001',
  '333-0826',
  '332-0005',
  '332-0029',
  '334-0054',
  '334-0053',
  '334-0055',
  '334-0071',
  '334-0052',
  '334-0051',
  '334-0057',
  '334-0072',
  '334-0058',
  '333-0834',
  '332-0023',
  '332-0024',
  '333-0865',
  '333-0823',
  '334-0074',
  '334-0075',
  '334-0067',
  '332-0014',
  '333-0844',
  '333-0845',
  '332-0015',
  '332-0007',
  '333-0831',
  '333-0862',
  '333-0815',
  '333-0804',
  '333-0814',
  '333-0822',
  '333-0832',
  '333-0857',
  '333-0863',
  '332-0016',
  '332-0017',
  '333-0816',
  '333-0866',
  '333-0848',
  '333-0851',
  '333-0853',
  '333-0868',
  '333-0856',
  '333-0847',
  '333-0855',
  '333-0867',
  '333-0852',
  '333-0854',
  '333-0869',
  '332-0006',
  '333-0807',
  '333-0808',
  '333-0803',
  '333-0811',
  '333-0806',
  '333-0805',
  '333-0802',
  '333-0817',
  '332-0032',
  '332-0022',
  '332-0034',
  '332-0033',
  '334-0061',
  '334-0068',
  '332-0035',
  '333-0833',
  '332-0021',
  '333-0813',
  '334-0062',
  '334-0064',
  '332-0025',
  '333-0821',
  '334-0066',
  '333-0801',
  '334-0063',
  '332-0003',
  '332-0013',
  '332-0012',
  '334-0076',
  '333-0849',
  '333-0843',
  '333-0842',
  '334-0065',
  '333-0835',
  '332-0027',
  '332-0026',
  '334-0013',
  '333-0846',
  '334-0056',
  '332-0028',
  '332-0011',
  '333-0861',
  '333-0864',
  '332-0002',
  '332-0004',
  '332-0000',
  '359-1133',
  '359-0025',
  '359-0038',
  '359-1131',
  '359-0024',
  '359-0022',
  '359-1132',
  '359-0000',
  '362-0025',
  '362-0012',
  '362-0013',
  '362-0073',
  '362-0031',
  '362-0065',
  '362-0034',
  '362-0062',
  '362-0046',
  '362-0071',
  '362-0047',
  '362-0058',
  '362-0044',
  '362-0075',
  '362-0074',
  '362-0001',
  '362-0037',
  '362-0008',
  '362-0048',
  '362-0022',
  '362-0007',
  '362-0063',
  '362-0064',
  '362-0024',
  '362-0033',
  '362-0051',
  '362-0004',
  '362-0054',
  '362-0052',
  '362-0035',
  '362-0072',
  '362-0067',
  '362-0057',
  '362-0006',
  '362-0043',
  '362-0005',
  '362-0021',
  '362-0026',
  '362-0023',
  '362-0016',
  '362-0032',
  '362-0059',
  '362-0055',
  '362-0011',
  '362-0061',
  '362-0041',
  '362-0017',
  '362-0076',
  '362-0014',
  '362-0015',
  '362-0002',
  '362-0036',
  '362-0045',
  '362-0042',
  '362-0066',
  '362-0000',
  '340-0002',
  '340-0007',
  '340-0053',
  '340-0032',
  '340-0003',
  '340-0042',
  '340-0046',
  '340-0047',
  '340-0052',
  '340-0045',
  '340-0011',
  '340-0056',
  '340-0054',
  '340-0012',
  '340-0014',
  '340-0055',
  '340-0022',
  '340-0043',
  '340-0015',
  '340-0016',
  '340-0051',
  '340-0021',
  '340-0036',
  '340-0005',
  '340-0035',
  '340-0031',
  '340-0044',
  '340-0048',
  '340-0034',
  '340-0004',
  '340-0013',
  '340-0041',
  '340-0028',
  '340-0024',
  '340-0025',
  '340-0023',
  '340-0033',
  '340-0006',
  '340-0017',
  '340-0026',
  '340-0027',
  '340-0000',
  '335-0001',
  '335-0004',
  '335-0002',
  '335-0005',
  '335-0003',
  '335-0000',
  '335-0022',
  '335-0015',
  '335-0013',
  '335-0014',
  '335-0034',
  '335-0033',
  '335-0035',
  '335-0037',
  '335-0011',
  '335-0016',
  '335-0024',
  '335-0012',
  '335-0021',
  '335-0026',
  '335-0036',
  '335-0027',
  '335-0031',
  '335-0032',
  '335-0023',
  '335-0025',
  '335-0000',
  '351-0007',
  '351-0023',
  '351-0000',
  '351-0111',
  '351-0101',
  '351-0102',
  '351-0103',
  '351-0113',
  '351-0115',
  '351-0105',
  '351-0106',
  '351-0114',
  '351-0116',
  '351-0112',
  '351-0104',
  '351-0100',
  '352-0021',
  '352-0015',
  '352-0033',
  '352-0004',
  '352-0025',
  '352-0003',
  '352-0035',
  '352-0014',
  '352-0032',
  '352-0017',
  '352-0024',
  '352-0001',
  '352-0006',
  '352-0013',
  '352-0031',
  '352-0034',
  '352-0011',
  '352-0012',
  '352-0016',
  '352-0002',
  '352-0023',
  '352-0022',
  '352-0000',
  '346-0029',
  '346-0028',
  '346-0036',
  '346-0035',
  '346-0101',
  '346-0000',
  '340-0826',
  '340-0806',
  '340-0821',
  '340-0835',
  '340-0822',
  '340-0834',
  '340-0824',
  '340-0803',
  '340-0813',
  '340-0804',
  '340-0823',
  '340-0807',
  '340-0825',
  '340-0816',
  '340-0802',
  '340-0812',
  '340-0833',
  '340-0811',
  '340-0801',
  '340-0805',
  '340-0808',
  '340-0831',
  '340-0814',
  '340-0815',
  '340-0832',
  '340-0800',
  '341-0036',
  '341-0054',
  '341-0033',
  '341-0031',
  '341-0059',
  '341-0011',
  '341-0022',
  '341-0055',
  '341-0006',
  '341-0004',
  '341-0026',
  '341-0008',
  '341-0043',
  '341-0027',
  '341-0021',
  '341-0007',
  '341-0009',
  '341-0034',
  '341-0015',
  '341-0037',
  '341-0035',
  '341-0016',
  '341-0017',
  '341-0038',
  '341-0051',
  '341-0044',
  '341-0023',
  '341-0041',
  '341-0056',
  '341-0012',
  '341-0050',
  '341-0058',
  '341-0005',
  '341-0053',
  '341-0057',
  '341-0003',
  '341-0052',
  '341-0024',
  '341-0028',
  '341-0025',
  '341-0042',
  '341-0032',
  '341-0045',
  '341-0018',
  '341-0000',
  '349-0135',
  '349-0133',
  '349-0125',
  '349-0132',
  '349-0122',
  '349-0101',
  '349-0134',
  '349-0124',
  '349-0121',
  '349-0115',
  '349-0123',
  '349-0114',
  '349-0127',
  '349-0128',
  '349-0100',
  '362-0803',
  '362-0813',
  '362-0806',
  '362-0804',
  '362-0800',
  '349-0217',
  '349-0204',
  '349-0203',
  '349-0200',
  '360-0826',
  '360-0033',
  '360-0816',
  '360-0821',
  '360-0105',
  '360-0823',
  '360-0813',
  '360-0025',
  '360-0812',
  '360-0803',
  '360-0847',
  '360-0046',
  '360-0004',
  '360-0001',
  '360-0012',
  '360-0035',
  '360-0032',
  '360-0026',
  '360-0831',
  '360-0015',
  '360-0036',
  '360-0814',
  '360-0023',
  '360-0846',
  '360-0031',
  '360-0834',
  '360-0804',
  '360-0855',
  '360-0853',
  '360-0854',
  '360-0018',
  '360-0825',
  '360-0037',
  '360-0022',
  '360-0024',
  '360-0047',
  '360-0013',
  '360-0817',
  '360-0841',
  '360-0842',
  '360-0857',
  '360-0014',
  '360-0235',
  '360-0811',
  '360-0852',
  '360-0021',
  '360-0833',
  '360-0856',
  '360-0043',
  '360-0815',
  '360-0042',
  '360-0161',
  '360-0034',
  '360-0844',
  '360-0843',
  '360-0845',
  '360-0824',
  '360-0041',
  '360-0045',
  '360-0822',
  '360-0162',
  '360-0044',
  '360-0000',
  '361-0074',
  '361-0046',
  '361-0077',
  '361-0045',
  '361-0007',
  '361-0044',
  '361-0073',
  '361-0055',
  '361-0071',
  '361-0022',
  '361-0032',
  '361-0047',
  '361-0037',
  '361-0057',
  '361-0054',
  '361-0053',
  '361-0041',
  '361-0078',
  '361-0076',
  '361-0023',
  '361-0042',
  '361-0026',
  '361-0043',
  '361-0021',
  '361-0016',
  '361-0052',
  '361-0038',
  '361-0031',
  '361-0072',
  '361-0075',
  '361-0056',
  '361-0062',
  '361-0051',
  '361-0017',
  '361-0000',
  '366-0801',
  '366-0812',
  '369-1106',
  '366-0041',
  '369-1105',
  '366-0000',
  '365-0068',
  '365-0064',
  '365-0063',
  '369-0123',
  '365-0033',
  '369-0137',
  '365-0054',
  '369-0112',
  '365-0078',
  '365-0034',
  '369-0116',
  '365-0028',
  '365-0041',
  '365-0057',
  '365-0055',
  '365-0047',
  '365-0035',
  '369-0113',
  '369-0111',
  '365-0074',
  '365-0067',
  '365-0044',
  '365-0032',
  '369-0114',
  '365-0056',
  '365-0036',
  '365-0065',
  '365-0037',
  '365-0052',
  '365-0073',
  '369-0124',
  '365-0043',
  '365-0039',
  '365-0045',
  '365-0031',
  '369-0136',
  '369-0121',
  '369-0115',
  '369-0131',
  '365-0046',
  '365-0038',
  '365-0076',
  '369-0132',
  '365-0042',
  '365-0062',
  '365-0066',
  '365-0053',
  '369-0122',
  '365-0075',
  '365-0051',
  '369-0135',
  '365-0077',
  '365-0000',
  '348-0017',
  '348-0016',
  '348-0005',
  '348-0038',
  '348-0052',
  '348-0064',
  '348-0000',
  '317-0075',
  '319-1415',
  '317-0074',
  '316-0036',
  '319-1418',
  '319-1225',
  '317-0076',
  '316-0012',
  '316-0022',
  '319-1221',
  '319-1413',
  '319-1412',
  '317-0071',
  '316-0015',
  '319-1417',
  '317-0064',
  '316-0005',
  '319-1222',
  '316-0035',
  '317-0073',
  '316-0002',
  '317-0077',
  '317-0056',
  '316-0006',
  '317-0065',
  '316-0001',
  '316-0021',
  '317-0066',
  '316-0003',
  '319-1416',
  '316-0013',
  '316-0033',
  '316-0007',
  '317-0053',
  '317-0051',
  '316-0031',
  '316-0032',
  '316-0011',
  '316-0023',
  '316-0014',
  '316-0004',
  '317-0061',
  '317-0052',
  '316-0034',
  '319-1414',
  '317-0062',
  '317-0072',
  '316-0026',
  '316-0024',
  '319-1223',
  '319-1224',
  '317-0055',
  '317-0054',
  '316-0025',
  '317-0063',
  '317-0000',
  '301-0838',
  '301-0857',
  '301-0801',
  '301-0045',
  '301-0046',
  '301-0805',
  '301-0851',
  '301-0856',
  '301-0006',
  '301-0817',
  '301-0048',
  '301-0005',
  '301-0021',
  '301-0001',
  '301-0852',
  '301-0044',
  '301-0034',
  '301-0032',
  '301-0033',
  '301-0847',
  '301-0031',
  '301-0841',
  '301-0816',
  '301-0839',
  '301-0854',
  '301-0002',
  '301-0042',
  '301-0004',
  '301-0007',
  '301-0023',
  '301-0843',
  '301-0017',
  '301-0003',
  '301-0855',
  '301-0846',
  '301-0853',
  '301-0043',
  '301-0832',
  '301-0022',
  '301-0047',
  '301-0842',
  '301-0831',
  '301-0041',
  '301-0000',
  '300-1221',
  '300-1204',
  '300-1283',
  '300-1286',
  '300-1211',
  '300-1281',
  '300-1232',
  '300-1216',
  '300-1235',
  '300-1233',
  '300-1217',
  '300-1231',
  '300-1203',
  '300-1223',
  '300-1237',
  '300-1236',
  '300-1234',
  '300-1215',
  '300-1202',
  '300-1205',
  '300-1201',
  '300-1207',
  '300-1206',
  '300-1222',
  '300-1200',
  '305-0062',
  '305-0804',
  '300-2632',
  '305-0031',
  '305-0005',
  '300-1253',
  '305-0835',
  '305-0076',
  '305-0874',
  '305-0901',
  '300-1240',
  '300-1269',
  '305-0073',
  '305-0071',
  '305-0061',
  '305-0855',
  '305-0002',
  '305-0023',
  '305-0045',
  '305-0853',
  '300-1243',
  '300-2611',
  '305-0845',
  '300-3253',
  '305-0801',
  '300-3256',
  '305-0851',
  '305-0026',
  '300-1255',
  '305-0053',
  '305-0034',
  '300-2653',
  '305-0816',
  '305-0818',
  '305-0064',
  '305-0821',
  '305-0877',
  '305-0824',
  '300-2622',
  '305-0871',
  '300-2661',
  '305-0011',
  '300-4246',
  '305-0055',
  '305-0854',
  '305-0822',
  '305-0856',
  '305-0811',
  '305-0072',
  '305-0066',
  '300-3266',
  '305-0024',
  '305-0001',
  '305-0817',
  '300-2642',
  '305-0074',
  '305-0844',
  '305-0018',
  '300-3251',
  '305-0028',
  '305-0036',
  '305-0013',
  '305-0003',
  '300-2637',
  '305-0043',
  '300-3264',
  '305-0004',
  '305-0833',
  '300-2655',
  '305-0872',
  '300-2662',
  '305-0063',
  '305-0813',
  '305-0042',
  '305-0075',
  '300-1251',
  '300-1246',
  '305-0047',
  '305-0868',
  '305-0846',
  '300-1245',
  '305-0077',
  '300-1252',
  '305-0032',
  '305-0802',
  '300-3263',
  '305-0067',
  '300-3255',
  '300-3257',
  '305-0834',
  '305-0006',
  '300-2635',
  '300-2633',
  '300-2648',
  '305-0068',
  '305-0012',
  '305-0873',
  '300-2634',
  '305-0052',
  '300-1268',
  '305-0044',
  '300-1260',
  '305-0054',
  '305-0831',
  '305-0832',
  '305-0876',
  '300-2624',
  '305-0902',
  '300-1247',
  '305-0814',
  '305-0051',
  '300-2631',
  '305-0865',
  '300-2643',
  '300-3262',
  '305-0843',
  '300-2638',
  '305-0875',
  '305-0029',
  '305-0805',
  '300-3261',
  '305-0025',
  '305-0857',
  '305-0823',
  '305-0046',
  '305-0033',
  '305-0027',
  '305-0812',
  '300-1242',
  '305-0852',
  '305-0866',
  '300-1254',
  '300-3267',
  '300-1241',
  '300-2656',
  '305-0035',
  '305-0056',
  '305-0903',
  '300-1244',
  '300-2654',
  '300-2646',
  '305-0065',
  '305-0803',
  '300-2621',
  '300-4245',
  '305-0841',
  '300-1257',
  '300-1256',
  '305-0842',
  '305-0861',
  '305-0836',
  '305-0017',
  '300-1248',
  '300-1249',
  '300-3252',
  '300-4247',
  '305-0000',
  '300-2353',
  '300-2402',
  '300-2359',
  '300-2401',
  '300-2413',
  '300-2403',
  '300-2415',
  '300-2416',
  '300-2406',
  '300-2417',
  '300-2404',
  '300-2357',
  '300-2358',
  '300-2300',
  '300-0504',
  '300-0500',
  '300-1603',
  '300-1626',
  '300-1633',
  '300-1632',
  '300-1605',
  '300-1624',
  '300-1625',
  '300-1636',
  '300-1631',
  '300-1622',
  '300-1606',
  '300-1604',
  '300-1600',
  '300-1152',
  '300-1157',
  '300-1156',
  '300-0326',
  '300-1155',
  '300-1161',
  '300-0300',
  '300-0425',
  '300-0414',
  '300-0415',
  '300-0400',
  '320-0845',
  '320-0818',
  '320-0801',
  '321-0912',
  '321-0952',
  '320-0034',
  '321-3221',
  '320-0821',
  '320-0047',
  '320-0049',
  '320-0812',
  '321-0966',
  '321-0961',
  '321-0962',
  '321-0166',
  '321-0973',
  '321-0118',
  '321-0964',
  '321-0107',
  '321-0101',
  '321-0102',
  '320-0802',
  '320-0014',
  '321-0161',
  '320-0811',
  '320-0823',
  '320-0036',
  '321-0108',
  '320-0013',
  '320-0855',
  '321-0913',
  '320-0058',
  '320-0051',
  '321-0106',
  '321-3224',
  '321-0111',
  '321-0965',
  '320-0822',
  '320-0002',
  '320-0844',
  '320-0048',
  '320-0842',
  '320-0037',
  '321-3231',
  '321-0951',
  '321-0951',
  '320-0066',
  '320-0065',
  '320-0042',
  '320-0848',
  '320-0024',
  '320-0043',
  '321-0922',
  '320-0814',
  '329-1104',
  '320-0824',
  '320-0825',
  '321-0924',
  '321-0923',
  '321-0914',
  '320-0055',
  '321-0945',
  '321-0935',
  '320-0032',
  '320-0831',
  '321-0132',
  '321-0113',
  '320-0864',
  '320-0022',
  '320-0867',
  '321-0103',
  '320-0061',
  '320-0846',
  '320-0847',
  '321-3236',
  '321-0974',
  '320-0806',
  '320-0805',
  '320-0857',
  '320-0851',
  '320-0816',
  '320-0035',
  '321-3237',
  '321-0123',
  '320-0856',
  '320-0056',
  '320-0017',
  '320-0053',
  '320-0031',
  '320-0003',
  '321-0911',
  '320-0045',
  '321-0968',
  '320-0004',
  '329-1105',
  '320-0815',
  '321-0906',
  '321-0114',
  '320-0057',
  '320-0052',
  '320-0023',
  '320-0861',
  '320-0046',
  '321-0917',
  '321-0152',
  '321-0151',
  '321-0967',
  '320-0866',
  '320-0862',
  '320-0826',
  '320-0813',
  '321-3222',
  '320-0015',
  '320-0843',
  '320-0827',
  '320-0828',
  '320-0027',
  '320-0026',
  '321-0941',
  '321-0167',
  '329-1103',
  '321-0953',
  '320-0062',
  '320-0054',
  '320-0021',
  '321-0168',
  '321-0985',
  '321-0944',
  '321-0925',
  '320-0832',
  '321-0905',
  '321-0918',
  '321-0931',
  '321-0932',
  '320-0011',
  '320-0804',
  '321-0164',
  '320-0833',
  '320-0038',
  '320-0074',
  '320-0033',
  '320-0817',
  '320-0803',
  '320-0807',
  '320-0041',
  '321-3225',
  '320-0863',
  '321-0921',
  '321-0165',
  '320-0044',
  '321-0963',
  '321-0143',
  '321-0142',
  '321-0942',
  '321-0943',
  '320-0808',
  '320-0835',
  '320-0025',
  '321-0969',
  '321-0163',
  '321-0984',
  '321-0983',
  '320-0865',
  '321-0954',
  '321-0169',
  '321-0934',
  '321-0933',
  '321-0162',
  '320-0836',
  '320-0012',
  '320-0016',
  '320-0837',
  '321-3226',
  '320-0063',
  '321-0904',
  '320-0834',
  '320-0838',
  '320-0841',
  '320-0072',
  '321-0139',
  '321-0000',
  '321-4367',
  '321-4345',
  '321-4351',
  '321-4346',
  '321-4300',
  '329-0521',
  '329-0606',
  '329-0617',
  '329-0604',
  '329-0618',
  '329-0605',
  '329-0607',
  '329-0600',
  '321-3321',
  '321-3325',
  '321-3300',
  '329-1224',
  '329-1200',
  '321-0238',
  '321-0200',
  '370-0725',
  '370-0722',
  '370-0504',
  '370-0500',
  '370-0614',
  '370-0600',
  '370-0715',
  '370-0700',
  '263-0024',
  '263-0025',
  '263-0052',
  '263-0033',
  '263-0034',
  '263-0032',
  '263-0035',
  '263-0031',
  '263-0053',
  '263-0042',
  '263-0041',
  '263-0043',
  '263-0044',
  '263-0003',
  '263-0015',
  '263-0014',
  '263-0002',
  '263-0051',
  '263-0013',
  '263-0016',
  '263-0011',
  '263-0021',
  '263-0005',
  '263-0001',
  '263-0012',
  '263-0023',
  '263-0054',
  '263-0022',
  '263-0004',
  '263-0000',
  '260-0852',
  '260-0804',
  '260-0002',
  '260-0855',
  '260-0833',
  '260-0856',
  '260-0834',
  '260-0815',
  '260-0018',
  '260-0816',
  '260-0811',
  '260-0813',
  '260-0033',
  '260-0853',
  '260-0017',
  '260-0011',
  '260-0857',
  '260-0835',
  '260-0802',
  '260-0016',
  '260-0832',
  '260-0034',
  '260-0841',
  '260-0021',
  '260-0031',
  '260-0027',
  '260-0028',
  '260-0022',
  '260-0843',
  '260-0822',
  '260-0822',
  '260-0812',
  '260-0844',
  '260-0026',
  '260-0013',
  '260-0024',
  '260-0042',
  '260-0003',
  '260-0023',
  '260-0006',
  '260-0005',
  '260-0025',
  '260-0854',
  '260-0801',
  '260-0032',
  '260-0803',
  '260-0041',
  '260-0004',
  '260-0015',
  '260-0045',
  '260-0808',
  '260-0014',
  '260-0012',
  '260-0807',
  '260-0044',
  '260-0831',
  '260-0842',
  '260-0001',
  '260-0806',
  '260-0805',
  '260-0851',
  '260-0007',
  '260-0821',
  '260-0000',
  '262-0019',
  '262-0017',
  '262-0043',
  '262-0002',
  '262-0003',
  '262-0048',
  '262-0041',
  '262-0023',
  '262-0005',
  '262-0013',
  '262-0045',
  '262-0014',
  '262-0011',
  '262-0004',
  '262-0031',
  '262-0012',
  '262-0047',
  '262-0044',
  '262-0024',
  '262-0016',
  '262-0018',
  '262-0042',
  '262-0025',
  '262-0021',
  '262-0046',
  '262-0032',
  '262-0033',
  '262-0026',
  '262-0007',
  '262-0022',
  '262-0015',
  '262-0006',
  '262-0001',
  '262-0000',
  '266-0002',
  '266-0007',
  '266-0000',
  '261-0012',
  '261-0005',
  '261-0013',
  '261-0001',
  '261-0002',
  '261-0004',
  '261-0003',
  '261-0024',
  '261-0023',
  '261-7190',
  '261-7101',
  '261-7102',
  '261-7103',
  '261-7104',
  '261-7105',
  '261-7106',
  '261-7107',
  '261-7108',
  '261-7109',
  '261-7110',
  '261-7111',
  '261-7112',
  '261-7113',
  '261-7114',
  '261-7115',
  '261-7116',
  '261-7117',
  '261-7118',
  '261-7119',
  '261-7120',
  '261-7121',
  '261-7122',
  '261-7123',
  '261-7124',
  '261-7125',
  '261-7126',
  '261-7127',
  '261-7128',
  '261-7129',
  '261-7130',
  '261-7131',
  '261-7132',
  '261-7133',
  '261-7134',
  '261-7135',
  '261-0025',
  '261-0021',
  '261-0026',
  '261-0011',
  '261-0022',
  '261-0014',
  '261-0000',
  '264-0031',
  '264-0011',
  '264-0015',
  '264-0016',
  '264-0006',
  '264-0007',
  '265-0077',
  '264-0020',
  '264-0023',
  '264-0017',
  '264-0001',
  '265-0045',
  '264-0014',
  '264-0012',
  '264-0028',
  '264-0029',
  '265-0073',
  '265-0044',
  '265-0076',
  '264-0024',
  '264-0002',
  '264-0004',
  '264-0003',
  '264-0005',
  '264-0025',
  '264-0033',
  '264-0036',
  '264-0026',
  '264-0034',
  '264-0035',
  '264-0032',
  '264-0037',
  '265-0072',
  '264-0027',
  '264-0021',
  '264-0000',
  '284-0024',
  '284-0014',
  '284-0041',
  '284-0013',
  '284-0042',
  '284-0011',
  '284-0027',
  '284-0001',
  '284-0033',
  '284-0015',
  '284-0021',
  '284-0037',
  '284-0036',
  '284-0034',
  '284-0031',
  '284-0023',
  '284-0043',
  '284-0016',
  '284-0012',
  '284-0022',
  '284-0032',
  '284-0008',
  '284-0035',
  '284-0000',
  '300-1513',
  '300-1524',
  '300-1511',
  '300-1531',
  '300-1525',
  '300-1534',
  '300-1535',
  '300-1515',
  '300-1512',
  '300-1516',
  '300-1514'
];

const canApplyGasRegion: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: BasicItemKeys.PostCode,
        operator: DetailConditionOperator.Some,
        targetValues: postCodeList.map(code => ({
          valueType: ValueOriginType.Fixed,
          valueKey: code
        }))
      }
    ]
  }
];

const canNotApplyGasRegion: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: BasicItemKeys.PostCode,
        operator: DetailConditionOperator.NotEqual,
        targetValues: postCodeList.map(code => ({
          valueType: ValueOriginType.Fixed,
          valueKey: code
        }))
      }
    ]
  }
];

const canApplyGasProjectType: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.SumamoruProjectType,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: 'CDE'
          }
        ]
      }
    ]
  }
];

// 申込画面に表示するコンポーネント定義
export const RegisteredInputContainers: Array<ContainerElement> = [
  {
    id: 'SelectBasicPlan',
    title: `CDエナジーの都市ガスもセットで申し込みますか？
（東京ガス（東京地区）以外の都市ガスおよびプロパンガス物件については、「ＣＤエナジーの都市ガスはセットで申込はしない/プロパンガス物件」を選択ください）`,
    confirmationTitle: '都市ガスの申し込み',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: ItemValueKey.UseGasPlanSelection,
        label: undefined,
        descriptionsBeforeInput: [
          {
            descriptionType: ComponentDescriptionType.Balloon,
            messages: ['ガスと電気のセット契約で、', '電気料金がオトクになります ']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.UseGasPlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'ＣＤエナジーの都市ガスもセットで申込む'
            },
            {
              value: '1',
              label: 'ＣＤエナジーの都市ガスはセットで申込はしない/プロパンガス物件'
            }
          ]
        }
      }
    ],
    isDisplayCondition: [...useElectronicCondition, ...canApplyGasRegion, ...canApplyGasProjectType]
  },
  {
    id: 'SelectBasicPlan',
    title: `CDエナジーの都市ガスもセットで申し込みますか？
（東京ガス（東京地区）以外の都市ガスおよびプロパンガス物件については、「ＣＤエナジーの都市ガスはセットで申込はしない/プロパンガス物件」を選択ください）`,
    confirmationTitle: '都市ガスの申し込み',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: ItemValueKey.UseGasPlanSelection,
        label: undefined,
        descriptionsBeforeInput: [
          {
            descriptionType: ComponentDescriptionType.Balloon,
            messages: ['ガスと電気のセット契約で、', '電気料金がオトクになります ']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.UseGasPlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '1',
          selectionItems: [
            {
              value: '0',
              label: 'ＣＤエナジーの都市ガスもセットで申込む',
              disabled: true
            },
            {
              value: '1',
              label: 'ＣＤエナジーの都市ガスはセットで申込はしない/プロパンガス物件'
            }
          ]
        }
      }
    ],
    isDisplayCondition: [...useElectronicCondition, ...canNotApplyGasRegion, ...canApplyGasProjectType]
  },
  {
    id: 'MoveInfo',
    title: 'お引越し情報',
    components: [
      {
        id: ItemValueKey.OccupyScheduledDate,
        label: '入居予定日',
        inputElement: {
          valueKey: ItemValueKey.OccupyScheduledDate,
          valueType: ValueOriginType.ApplicationParams,
          inputType: ComponentType.FixedText,
          converter: value =>
            moment(value)
              .tz('Asia/Tokyo')
              .format(dateDisplayFormat)
        }
      },
      {
        id: ItemValueKey.ElectricityStartDate,
        label: '電気：利用開始日',
        inputElement: {
          valueKey: ItemValueKey.ElectricityStartDate,
          inputType: ComponentType.KeyboardDateInput,
          minDateOffset: 2,
          useBusinessDayOffset: true
        },
        descriptionsAfterInput: [
          {
            messages: [
              '※第２営業日（土日祝除く平日）以内に電気のご利用をご希望の場合、ＣＤエナジーダイレクトのお客さまセンターへお電話いただき、「スマモル賃貸プラン」を希望とお伝えください。',
              '連絡先：０１２０－８１１－７９２'
            ]
          }
        ],
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.ElectricityBasePlanSelection,
        label: '電気お申込みプラン',
        inputElement: {
          valueKey: ItemValueKey.ElectricityBasePlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'スマモル賃貸プランB'
            },
            {
              value: '1',
              label: 'スマモル賃貸プランAE'
            }
          ]
        },
        isDisplayCondition: useElectronicCondition,
        descriptionsAfterInput: [
          {
            messages: [
              '※スマモル賃貸プランBは東京電力の従量電灯B相当、スマモル賃貸プランAEは、オール電化のお客さま向け料金メニューです。',
              '※ご契約の容量は、お住いになるマンションの契約電流となります。'
            ]
          }
        ]
      },
      {
        id: ItemValueKey.ElectricityOptionPlanSelection,
        label: '電気オプション割引',
        inputElement: {
          valueKey: ItemValueKey.UseGasPlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          readonly: true,
          selectionItems: [
            {
              value: '0',
              label: 'ガスセット割引'
            },
            {
              value: '1',
              label: 'なし',
              notDisplay: true
            }
          ],
          /* ガス利用の場合のみに表示される条件 */
          isDisableCondition: [
            {
              judgeRuleType: ConditionsResultJudgeRule.Some,
              detailConditions: [...notUseGasCondition[0].detailConditions, ...canNotApplyGasRegion[0].detailConditions]
            }
          ]
        },
        isDisplayCondition: [...useElectronicCondition, ...canApplyGasRegion, ...canApplyGasProjectType],
        descriptionsAfterInput: [
          {
            messages: ['※ガスとセットで申し込まれる場合、「ガスセット割」が適用されます。']
          }
        ]
        /* ガス利用の場合のみに表示される条件 */
        // isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasStartDate,
        label: 'ガス：開栓希望日・時間帯',
        descriptionsBeforeInput: [
          {
            messages: ['※ガス開栓にはお客様の立ち会いが必要となります。']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.GasStartDate,
          inputType: ComponentType.KeyboardDateInput,
          minDateOffset: 5,
          useBusinessDayOffset: true
        },
        showOnlyInput: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasStartDateTime,
        label: 'ガス:¥n開栓希望日・時間帯',
        inputElements: [
          {
            valueKey: ItemValueKey.GasStartDate,
            inputType: ComponentType.KeyboardDateInput,
            minDateOffset: 5,
            useBusinessDayOffset: true
          },
          {
            valueKey: '¥n',
            inputType: ComponentType.FixedText,
            valueType: ValueOriginType.Fixed
          },
          {
            valueKey: ItemValueKey.GasStartTimeSelection,
            inputType: ComponentType.SelectItemInput,
            selectionItems: [
              {
                value: '0',
                label: 'ＡＭ'
              },
              {
                value: '1',
                label: 'ＰＭ（１３-１５時）'
              },
              {
                value: '2',
                label: 'ＰＭ（１５-１７時）'
              },
              {
                value: '3',
                label: 'ＥＶＥ（１７-１９時）'
              },
              {
                value: '4',
                label: 'ＰＭ（１３-１７時）'
              }
            ]
          }
        ],
        showOnlyConfirmation: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasStartTimeSelection,
        inputElement: {
          valueKey: ItemValueKey.GasStartTimeSelection,
          inputType: ComponentType.SelectItemInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'ＡＭ'
            },
            {
              value: '1',
              label: 'ＰＭ（１３-１５時）'
            },
            {
              value: '2',
              label: 'ＰＭ（１５-１７時）'
            },
            {
              value: '3',
              label: 'ＥＶＥ（１７-１９時）'
            },
            {
              value: '4',
              label: 'ＰＭ（１３-１７時）'
            }
          ],
          styleOption: {
            marginTop: 0
          }
        },
        descriptionsAfterInput: [
          {
            messages: [
              '※第５営業日（土日祝除く平日）以内にガスの開栓をご希望の場合、ＣＤエナジーダイレクトで受付が出来ません。申し訳ございませんが、東京ガスへお申込みをお願いします。'
            ]
          }
        ],
        showOnlyInput: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.WitnessGasStart,
        label: '開栓の立ち会い者',
        inputElement: {
          valueKey: ItemValueKey.WitnessGasStart,
          inputType: ComponentType.SelectItemInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: '本人'
            },
            {
              value: '1',
              label: '家主'
            },
            {
              value: '2',
              label: '管理人'
            },
            {
              value: '3',
              label: 'その他'
            }
          ]
        },
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.WitnessNameGasStart,
        label: '立ち会い者氏名',
        inputElement: {
          valueKey: ItemValueKey.WitnessNameGasStart,
          inputType: ComponentType.TextFieldInput,
          placeholder: '姓 名'
        },
        isDisplayCondition: [
          {
            detailConditions: [
              {
                valueType: ValueOriginType.InputValues,
                valueKey: ItemValueKey.WitnessGasStart,
                operator: DetailConditionOperator.Some,
                targetValues: [
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '1'
                  },
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '2'
                  },
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '3'
                  }
                ]
              }
            ]
          },
          ...useGasCondition
        ]
      },
      {
        id: ItemValueKey.PhoneNumberForGasStart,
        label: 'ガス開栓日に連絡可能な電話番号',
        inputElement: {
          valueKey: ItemValueKey.PhoneNumberForGasStart,
          inputType: ComponentType.TextFieldInput,
          placeholder: '08012345678',
          inputComponentType: 'tel'
        },
        descriptionsAfterInput: [
          {
            messages: ['※立ち会い者と連絡可能な電話番号を入力ください']
          }
        ],
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasBasePlanSelection,
        label: 'ガスお申込みプラン',
        inputElement: {
          valueKey: ItemValueKey.GasBasePlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'ベーシックガス'
            },
            {
              value: '1',
              label: 'ゆかぽかガス'
            }
          ]
        },
        descriptionsAfterInput: [
          {
            messages: [
              '※ベーシックガスは東京ガスの一般料金、ゆかぽかガスはガス温水式床暖房をご利用のお客さま向け料金です。'
            ]
          }
        ],
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasOptionPlanSelection,
        label: 'ベーシックガスのオプション割引',
        inputElement: {
          valueKey: ItemValueKey.GasBasePlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          readonly: true,
          selectionItems: [
            {
              value: '0',
              label: '電気セット割引'
            },
            {
              value: '1',
              label: 'なし',
              notDisplay: true
            }
          ],
          /* ガス利用の場合のみに表示される条件 */
          isDisableCondition: notUseBasicGasPlan
        },
        descriptionsAfterInput: [
          {
            messages: ['※スマモル賃貸プランとベーシックガスを申し込みの場合、「電気セット割引」が適用されます。']
          }
        ],
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: [
          {
            detailConditions: [
              {
                valueType: ValueOriginType.InputValues,
                valueKey: ItemValueKey.GasBasePlanSelection,
                operator: DetailConditionOperator.NotEqual,
                targetValues: [
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '1'
                  }
                ]
              }
            ]
          },
          ...useGasCondition
        ]
      },
      {
        id: 'gas_option_discount2',
        label: 'ゆかぽかガスのオプション割引',
        descriptionsAfterInput: [
          {
            messages: [
              '※ゆかぽかガスのオプション割引の適用を希望される場合は、本お申込み後、ＣＤエナジーダイレクトのお客さまセンターへご連絡をお願いします。',
              '連絡先：０１２０－８１１－７９２'
            ]
          }
        ],
        showOnlyInput: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: [
          {
            detailConditions: [
              {
                valueType: ValueOriginType.InputValues,
                valueKey: ItemValueKey.GasBasePlanSelection,
                operator: DetailConditionOperator.Equal,
                targetValues: [
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '1'
                  }
                ]
              }
            ]
          },
          ...useGasCondition
        ]
      }
      // {
      //   id: 'gas_option_discount2',
      //   label: 'ガスオプション割引2',
      //   inputElement: {
      //     valueKey: 'gas_option_discount2',
      //     inputType: ComponentInputType.RadioButton,
      //     initialValue: '3',
      //     selectionItems: [
      //       {
      //         value: '0',
      //         label: '浴室暖房割'
      //       },
      //       {
      //         value: '1',
      //         label: 'エコ給湯割'
      //       },
      //       {
      //         value: '2',
      //         label: 'ダブル割'
      //       },
      //       {
      //         value: '3',
      //         label: '割引なし'
      //       }
      //     ]
      //   },
      //   descriptionsAfterInput: [
      //     {
      //       descriptionType: ComponentDescriptionType.Normal,
      //       messages: [
      //         '※浴室暖房割はガス浴室暖房乾燥機をご利用のお客様向け、エコ給湯割は家庭用省エネ給湯器をご利用のお客様向け、ダブル割はガス浴室暖房乾燥機と家庭用省エネ給湯器の両方をご利用のお客様向けのオプション割引です。'
      //       ]
      //     }
      //   ],
      //   /* ガス利用の場合のみに表示される条件 */
      //   isDisplayCondition: [
      //     {
      //       detailConditions: [
      //         {
      //           valueType: ComponentInputValueType.InputValues,
      //           valueKey: 'gassPlan',
      //           operator: ComponentConditionOperator.Equal,
      //           targetValues: [
      //             {
      //               valueType: ComponentInputValueType.Fixed,
      //               valueKey: '1'
      //             }
      //           ]
      //         }
      //       ]
      //     },
      //     ...useGasCondition
      //   ]
      // }
    ]
  },
  {
    id: 'CustomerInformation',
    title: 'お客様情報',
    components: [
      {
        id: ItemValueKey.CustomerNamePrefix,
        inputElement: {
          valueKey: ItemValueKey.CustomerNamePrefix,
          inputType: ComponentType.KanaNameAutoCompleteInput
        },
        showOnlyInput: true
      },
      {
        id: ItemValueKey.CustomerName,
        label: '氏名',
        inputElements: [
          {
            valueKey: ItemValueKey.CustomerLastName,
            inputType: ComponentType.TextFieldInput
          },
          {
            valueKey: ItemValueKey.CustomerFirstName,
            inputType: ComponentType.TextFieldInput
          }
        ],
        showOnlyConfirmation: true
      },
      {
        id: ItemValueKey.CustomerNameKana,
        label: '氏名(カナ)',
        inputElements: [
          {
            valueKey: ItemValueKey.CustomerLastNameKana,
            inputType: ComponentType.TextFieldInput
          },
          {
            valueKey: ItemValueKey.CustomerFirstNameKana,
            inputType: ComponentType.TextFieldInput
          }
        ],
        showOnlyConfirmation: true
      },
      {
        id: ItemValueKey.CustomerBirthDay,
        label: '生年月日',
        inputElement: {
          valueKey: ItemValueKey.CustomerBirthDay,
          inputType: ComponentType.SelectDateInput
        }
      },
      {
        id: ItemValueKey.CustomerPhoneNumber,
        label: '電話番号',
        inputElement: {
          valueKey: ItemValueKey.CustomerPhoneNumber,
          inputType: ComponentType.TextFieldInput,
          placeholder: '08012345678',
          inputComponentType: 'tel'
        }
      },
      {
        id: ItemValueKey.CustomerGender,
        label: '性別',
        inputElement: {
          valueKey: ItemValueKey.CustomerGender,
          inputType: ComponentType.SelectItemInput,
          placeholder: '性別を選択',
          initialValue: '',
          selectionItems: [
            {
              value: '1',
              label: '男性'
            },
            {
              value: '2',
              label: '女性'
            },
            {
              value: '3',
              label: '無回答'
            }
          ]
        }
      },
      {
        id: ItemValueKey.CustomerEmail,
        label: 'メールアドレス',
        inputElement: {
          valueKey: ItemValueKey.CustomerEmail,
          inputType: ComponentType.EmailInput
        }
      }
    ]
  },
  {
    id: ItemValueKey.PaymentMethodSelection,
    title: 'お支払い方法',
    confirmationTitle: 'お支払い手続き',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: 'paymentInfo',
        label:
          '口座振替・クレジットカードをお選びいただけます。ご契約開始通知に同封の案内に沿ってお手続きをお願いいたします。',
        confirmationLabel: '',
        descriptionsBeforeInput: [
          {
            messages: ['※お手続きが完了するまでは払込票でのお支払いとなります。']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.PaymentMethodSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: '口座振替'
            },
            {
              value: '1',
              label: 'クレジットカード'
            }
          ]
        }
      }
    ],
    isDisplayCondition: useElectronicCondition
  }
];

export const ableGoToNextPageConditionDefinition = {
  needToInput: {
    inputValues: [
      ItemValueKey.ElectricityStartDate,
      ItemValueKey.GasStartDate,
      ItemValueKey.WitnessNameGasStart,
      ItemValueKey.PhoneNumberForGasStart,
      ItemValueKey.CustomerFirstName,
      ItemValueKey.CustomerLastName,
      ItemValueKey.CustomerFirstNameKana,
      ItemValueKey.CustomerLastNameKana,
      ItemValueKey.CustomerPhoneNumber,
      ItemValueKey.CustomerGender,
      ItemValueKey.CustomerEmailInput,
      ItemValueKey.CustomerEmailConfirmation
    ]
  },
  hasNoError: {
    inputValues: [
      ItemValueKey.ElectricityStartDate,
      ItemValueKey.GasStartDate,
      ItemValueKey.PhoneNumberForGasStart,
      ItemValueKey.CustomerFirstName,
      ItemValueKey.CustomerLastName,
      ItemValueKey.CustomerFirstNameKana,
      ItemValueKey.CustomerLastNameKana,
      ItemValueKey.CustomerPhoneNumber,
      ItemValueKey.CustomerEmailInput,
      ItemValueKey.CustomerEmailConfirmation
    ]
  },
  satisfySpecificCondition: [
    {
      detailConditions: [
        {
          valueType: ValueOriginType.InputValues,
          valueKey: ItemValueKey.AgreeThePolicy,
          operator: DetailConditionOperator.Equal,
          targetValues: [
            {
              valueType: ValueOriginType.Fixed,
              valueKey: true
            }
          ]
        }
      ]
    }
  ]
};

type PageDisplayTextType = {
  initialTemplateDisplayPlanLabel: string;
  initialTemplateDisplayPlanName: string;
  initialTemplateNameDescriptions: string[];
  initialTemplateDisplayNameCautions: string[];
  initialTemplateDisplayDetailCautions: DescriptionElement[][];
  initialTemplateSupplements: DescriptionElement[][];
};

export const pageDisplayText: PageDisplayTextType = {
  initialTemplateDisplayPlanLabel: '電気契約申し込み',
  initialTemplateDisplayPlanName: 'CDエナジーの電気(スマモル賃貸プラン)に申し込む',
  initialTemplateNameDescriptions: [
    'ご入居予定の物件には、CDエナジーの電気「スマモル賃貸プラン」が標準採用されています。'
  ],
  initialTemplateDisplayNameCautions: ['スマモルプランに申し込まない場合は、物件の管理会社へご連絡ください。'],
  initialTemplateDisplayDetailCautions: [
    [
      { text: '各種サービスと併せて、', type: ComponentDescriptionType.Normal },
      {
        text: 'CDエナジーの電気「スマモル賃貸プラン」に契約申込希望の場合は、物件の管理会社へご連絡ください。',
        type: ComponentDescriptionType.Caution
      },
      {
        text: '原則、入居日を過ぎてからスマモル賃貸プランに加入することは出来ません。',
        type: ComponentDescriptionType.Normal
      },
      {
        text:
          'CDエナジーの電気「スマモル賃貸プラン」に加入されない方でも、「スマモル賃貸サービス」へのお申し込みは必要になります。',
        type: ComponentDescriptionType.Normal
      }
    ],
    []
  ],
  initialTemplateSupplements: [
    [
      {
        text: '別途、郵送されるパンフレットでご説明している電気プラン、サービスは',
        type: ComponentDescriptionType.Normal
      },
      {
        text: 'こちら',
        type: ComponentDescriptionType.ExternalLink,
        path: 'https://www.cdedirect.co.jp/pdf/sumamoruchintai_CDED_202404.pdf'
      },
      {
        text: 'からご確認いただけます。',
        type: ComponentDescriptionType.Normal
      }
    ]
  ]
};

export const getFooterOptions = {
  privacyPolicy: {
    text: 'CDエナジーダイレクトプライバシーポリシー',
    link: 'https://www.cdedirect.co.jp/pdf/privacy.pdf'
  }
};

export const baseFormText = {
  useInformationConfirmation: [
    '上記でご入力いただいた情報は、「優待・割引サービス」「駆けつけサービス」で利用されます。また、お客さまが入居契約した物件の管理会社もご入力いただいた情報を利用します。'
  ],
  needToConfirmContent: [
    [
      {
        text: '駆けつけサービス契約約款',
        type: ComponentDescriptionType.InternalLink,
        path: '/static/security_service_term.pdf'
      },
      {
        text: '・',
        type: ComponentDescriptionType.Normal
      },
      {
        text: '優待・割引サービス利用規約',
        type: ComponentDescriptionType.InternalLink,
        path: '/static/benefit_one_term.pdf'
      },
      {
        text: '・',
        type: ComponentDescriptionType.Normal
      },
      {
        text: 'プライバシーポリシー',
        type: ComponentDescriptionType.InternalLink,
        link: 'https://www.cdedirect.co.jp/pdf/privacy.pdf'
      },
      {
        text: 'にご同意の上、申込内容のご確認へお進みください。',
        type: ComponentDescriptionType.Normal
      }
    ]
  ]
};

export type DescriptionElement = {
  text: string;
  type: ComponentDescriptionType;
  link?: string;
  path?: string;
};

export const getImportantConfirmationText = (
  env: 'develop' | 'staging' | 'production'
): {
  electronic: DescriptionElement[][];
  gas?: DescriptionElement[][];
  service?: DescriptionElement[][];
  company?: DescriptionElement[][];
} => {
  return {
    electronic: [
      [
        {
          text: '電気需給契約に関する重要事項説明',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            'お客さまが、株式会社ＣＤエナジーダイレクト（以下「当社」といいます。）に電気使用の申し込みをしていただくにあたり、当社が電気事業法に基づき説明し、お客さまにご確認いただきたい主要な供給条件は以下のとおりです。なお、電気の供給及び使用に関する契約（以下「電気需給契約」といいます。）の詳細は、電気基本契約要綱及び電気個別要綱（以下「要綱等」といいます。）に定めています。当社は、電気事業法第2条の13第2項に基づく書面の交付（契約締結前書面）及び同法第2条の14第1項に基づく書面の交付（契約締結後書面）について、書面でお知らせする事項を除いては、書面交付に代えて、要綱等を当社のホームページに掲載する方法によりこれを提供いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１．電気使用の申し込み、電気需給契約の成立及び契約期間',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが新たに電気需給契約を希望される場合は、あらかじめ要綱等を承諾のうえ、当社が必要とする事項を明らかにし、所定の様式により申し込みをしていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)申し込みは、当社所定の場所で受け付けます。なお、当社が適当と判断した場合は、口頭、電話、インターネット等による申し込みを受け付けることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)電気需給契約の申し込みをされる場合は、お客さまは、あらかじめ、次の事項を承諾するものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　ア　要綱等によって支払いを要することとなった料金その他の債務について、当社の定める期日を経過してなお支払われない場合等には、お客さまの氏名、住所、支払状況等の情報（お客さまを識別できる情報をいいます）を他の小売電気事業者等へ当社が通知すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　イ　東京電力パワーグリッド株式会社（以下「接続供給会社」といいます。）が託送供給等約款及びその他の供給条件等（以下「託送約款等」といいます。）において定める需要家等に関する事項を遵守すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　ウ　電気需給契約に基づきお客さまから申し出ていただいた事項のうち、託送約款等に基づく接続供給のために接続供給会社が必要とする事項について、接続供給会社に当社が情報を提供すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)電気需給契約は、お客さまからの申し込みに対して、当社が承諾したときに成立いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)契約期間は、電気需給契約が成立した日から、料金適用開始の日が属する年度（4月1日から翌年3月31日までの期間をいいます）の末日までといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)契約期間満了に先立って、原則として、契約期間満了日の３か月前までにお客さまと当社の双方が、電気需給契約の廃止又は変更について書面等による申し入れを行わない場合は、電気需給契約は、契約期間満了後も1年ごとに同一条件で継続されるものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(7)当社は、法令、電気の需給状況、供給設備の状況、料金の支払状況（既に消滅しているものを含む他の契約の料金支払状況を含みます。）その他によって、申し込みの全部または一部をお断りすることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '２．使用開始予定日',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社へスイッチングされる場合の供給開始予定日は、原則として、従前の小売電気事業者（旧小売電気事業者）との解約や接続供給会社との託送供給契約成立等の手続きが完了した後の、接続供給会社の託送約款等に定める計量日（次回計量日又は次々回計量日）といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)転宅等で新たに電気の使用を開始される場合の供給開始予定日は、お客さまが希望される日を基準として、協議することといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)供給開始後に、ご契約内容をお知らせする書面を送付いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)旧小売電気事業者への解約連絡は当社がお客さまに代わり行いますので、当社の供給開始とともに旧小売電気事業者との契約は解約されます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)万が一、供給開始予定日より前にスイッチングの申し込みをキャンセルされる場合は、供給開始予定日の３営業日前までに当社へその旨をお申し出いただく必要がございます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '３．料金プラン・割引種別の適用等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(1)料金プラン・割引種別はお客さまからの申し込みに基づき適用いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)当社とのガス需給契約の解約等で適用条件を満たさなくなった場合は、すみやかにその旨を当社へ連絡いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '４．電気ご使用量の計量や電気料金の算定方法等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)接続供給会社が託送約款等に基づき計量した値を用いて、その料金算定期間の使用量を算定いたします。計量器は、託送約款等に基づき接続供給会社が設置いたします。料金の算定期間における使用量は、30分ごとの使用量の合計として算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],

      [
        {
          text:
            '(2)計量器の故障や特別の事情等があり、使用量の算定に計量値等を用いることが適当でないときには、託送約款等に定めるところにより、お客さまと当社との協議によって使用量を定めます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)当社は、その使用量をWEB会員サービス「カテエネ」・「ビジエネ」によりお客さまへお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '（供給開始後に当社より送付するご契約内容をお知らせする書面内に「カテエネ」・「ビジエネ」の登録方法を記載しています。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)当社は、電気個別要綱の料金表を適用して、その使用量に基づき電気料金を算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)料金算定期間は、計量日（電力量又は最大需要電力等が記録型計量器に記録される日をいいます。）から次の計量日の前日までの期間とします。また、お引っ越し等により、ご使用期間が1か月に満たない場合、要綱等に定める算定式に基づき日割り計算を行います。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)電気料金は、契約電流、契約容量若しくは契約電力によって決まる「基本料金」と、電気ご使用量に応じて決まる「電力量料金（燃料費調整額を含む）」の合計（割引制度の適用がある場合は、その合計から割引額を差し引いた金額）に、再生可能エネルギー発電促進賦課金を加えたものといたします。ただし、適用する割引種別により割引額には上限があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　※燃料費調整制度および再生可能エネルギー発電促進賦課金の詳細及び適用単価は、当社ホームページ等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '＜計算方法＞',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '電気料金 = 基本料金（税込）+ 電力量料金単価（税込）×電気ご使用量 ± 燃料費調整単価（税込）×電気ご使用量 - 割引額（税込） + 再生可能エネルギー発電促進賦課金単価（税込）×電気ご使用量',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(7)料金プランの料金表及び適用条件については、要綱等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '５．電気料金等のお支払い',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)電気料金又は延滞利息については毎月、工事費負担金その他についてはそのつど、当社が指定した金融機関等を通じてお支払いいただきます。なお、電気料金又は延滞利息は原則として、口座振替又はクレジットカード払いによりお支払いいただきます。ただし、供給開始後、お支払方法の手続きが完了するまでに電気料金又は延滞利息をお支払いいただく場合等には、振込用紙によりお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが、電気需給契約と同一の需要場所において当社とガス需給契約を締結されている場合の電気料金は、原則として、そのガス需給契約におけるガス料金の支払いと同一の方法により、ガス料金とあわせてお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)電気料金の支払義務は、要綱等の定めに基づき、原則として、検針日の属する月の翌月第３営業日に発生し、支払期限日は、支払義務発生日の翌日から起算して30日目といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)支払期限日を経過してもなお電気料金のお支払いがない場合は、要綱等の定めに基づき延滞利息を申し受けます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '６．供給電圧及び周波数',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　当社が供給する電気の供給電圧及び周波数は次の通りです。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[供給電圧]標準電圧100ボルト又は200ボルト',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[周波数]標準周波数50ヘルツ（一部地域は60ヘルツ）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '７．お客さまの申し出による電気需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまのお申し出による契約の変更及び転宅等による解約については、当社ホームページ又は当社問い合わせ先へのお電話により、お手続きをしていただきます。転宅等による解約を希望される場合は、解約を希望される日の３営業日前までに当社へお申し出いただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが当社から他の小売電気事業者へスイッチングされる場合の解約については、新たな小売電気事業者に対し契約の申し込みをしていただきます。（当社への解約のお申し出は不要です。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)お客さまが契約電流、契約容量若しくは契約電力を新たに設定し、又は増加された日以降1年に満たないで電気の使用を廃止しようとし、又は契約容量若しくは契約電力を減少しようとされる場合は、当社は電気需給契約の消滅又は変更の日に、電気料金及び工事費を精算していただきます。ただし、将来の需要等を考慮して供給設備を常置する場合、又は非常変災等やむを得ない理由による場合を除きます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '８．当社からの申し出による電気需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社は、要綱等を変更することがあります。この場合には、原則として、料金にかかわる供給条件は変更の直後の計量日から、その他の供給条件は変更を行った日から、変更後の要綱等によります。その場合には、変更後の要綱等を当社のホームページに掲示する方法又はその他当社が適当と判断した方法により公表いたします。なお、お客さまは、変更を承諾いただけない場合は契約を解約することができます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)要綱等又は電気需給契約の内容を変更する場合は、次項に定める場合を除き、電気事業法第2条の13第2項に基づく供給条件の説明及び書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、説明を要する事項のうち当該変更をしようとする事項のみを説明し記載すれば足りるものといたします。また、同法第2条の14第1項に基づく書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、当社の名称及び住所、契約年月日、当該変更をした事項並びに供給地点特定番号のみを記載すれば足りるものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)要綱等又は電気需給契約の内容について、法令の制定又は改廃に伴い当然必要とされる形式的な変更その他の電気需給契約の実質的な変更を伴わない変更をしようとする場合、電気事業法第2条の13第2項に基づく供給条件の説明については、説明を要する事項のうち当該変更をしようとする事項の概要のみを書面交付することなく説明すれば足りるものといたします。また同法第2条の14第1項に基づく書面の交付については、これを行わないものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまが支払期限日をさらに20日経過しても電気料金のお支払いがない場合、当社との他の契約（すでに消滅しているものを含みます。）の電気料金についてお支払いがない場合、又は要綱等によって支払いを要することとなった電気料金以外の債務のお支払いがない場合には、当社は電気需給契約を解約することがあります。また、電気を不正に使用した等、当社が要綱等に定める一定の事由に該当するときは、電気の供給を停止又は解約することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまが、当社に電気の使用廃止の通知をすることなく移転され、電気の使用がないことが明らかな場合には、当社及び接続供給会社が需給を終了させるための処置を行なった日に契約を解約いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '９．工事費等の負担',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　当社が、接続供給会社からお客さまの需要場所に対応する供給地点に係る工事費等の負担を求められた場合には、当社は、その金額をお客さまから、原則として、当社又は接続供給会社の工事着手前に申し受けます。また、当社は、接続供給会社の設計変更、材料単価の変動その他特別の事情によって工事費等に著しい差異が生じた場合等において、接続供給会社との間で工事完成後に工事費等の精算を行う場合は、お客さまとの間で工事費等を精算するものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１０．違約金及び設備賠償金',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが、電気工作物の改変等によって不正に電気を使用された場合等で、そのために電気料金の全部又は一部の支払いを免れた場合には、当社は、その免れた金額の3倍に相当する金額を、違約金として申し受けます。免れた金額は、適正な供給条件に基づいて算定された金額と、不正な使用方法に基づいて算定された金額との差額といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(2)不正に使用した期間が確認できないときは、6か月以内で当社が決定した期間といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)お客さまが故意又は過失によって、その需要場所内の当社又は接続供給会社の電気工作物、電気機器その他の設備を損傷し、又は亡失した場合は、その設備について、当社設備の場合かつ修理可能であるときは修理費、当社設備の場合かつ亡失又は修理不可能であるときは、帳簿価額と取替工費との合計額を賠償していただきます。また、接続供給会社の設備の場合は、接続供給会社に生じた損害の賠償に要する金額を賠償していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１１．需要場所への立ち入りによる業務の実施',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　当社又は接続供給会社は、供給設備又は計量器等の設計、施工、改修又は検査や、計量器の検針又は計量値の確認等を実施するため、お客さまの承諾を得てお客さまの土地若しくは建物に立ち入らせていただくことがあります。この場合には、正当な理由がない限り、立ち入ること及び業務を実施することを承諾していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１２．保安に対するお客さまの協力',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが、次のいずれかについてお気づきの場合には、すみやかに当社及び接続供給会社にご連絡いただくようご協力ください。この場合には、接続供給会社は、ただちに適当な処置をいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　ア　電気の供給に必要な電気工作物に異状、若しくは故障があり、又は異状若しくは故障が生ずるおそれがあると認めた場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　イ　お客さまの電気工作物に異状若しくは故障があり、又は異状若しくは故障が生ずるおそれがあり、それが接続供給会社の供給設備に影響を及ぼすおそれがあると認めた場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが、接続供給会社の供給設備を使用しないことが明らかな場合で、接続供給会社が保安上必要と認めるときは、その期間について、接続供給会社は(1)に準じて、適当な処置をいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)その他、接続供給会社の託送約款等に定める事項を遵守していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１３．その他',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが当社にスイッチングされると、旧小売電気事業者との契約は解約となりますので、その契約内容によっては旧小売電気事業者に対する解約金が発生する場合があります。また、旧小売電気事業者で利用されているポイント等のサービスが失効・停止する場合等、お客さまの不利益になる事項が発生する場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)クーリング・オフにより契約を解除された場合や当社から契約を解約した場合等で、お客さまが無契約状態となったときには、電気の供給が停止いたしますので、契約の締結を希望される小売電気事業者へ申し込みいただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)当社又は接続供給会社が解約をし、又は供給若しくは使用の制限、中止若しくは停止をしたために、お客さま又は第三者が損害を受けられても、当社の責めに帰すべき事由がないときは、当社は賠償の責任を負いません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)当社は、個人情報の一部を共同利用することがあります。共同利用における利用項目、利用者の範囲、利用目的等の詳細は当社のプライバシーポリシーをご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１４．「KODOMO新聞でんき」限定',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　料金プランが「KODOMO新聞でんき」のお客さまは、上記事項に加えて、以下の事項についてご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(1)当社は、要綱等に基づき、お客さまに読売KODOMO新聞をお届けします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(2)配達先は、電気需給契約の需要場所と同一の場所といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)配達期間は、電気需給契約が成立した日の属する月の翌月から電気需給契約の廃止までといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)配達日は、前項の配達期間内において読売KODOMO新聞が発行された日といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまが電気需給契約の申し込み時に、既に同一配達先に向けた読売KODOMO新聞の新聞購読契約（以下「既契約」といいます。）を締結している場合、電気需給契約が成立した日の属する月の末日をもって終了するよう当社はお客さまの配達先を担当する読売新聞販売店（以下「YC」といいます。）へ連絡いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(6) 当社は、配達に必要となるお客さまの個人情報を、株式会社読売ハートサービス及びYCに提供いたします。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    gas: [
      [
        {
          text: 'ガス需給契約に関する重要事項説明',
          type: ComponentDescriptionType.Title
        }
      ],

      [
        {
          text:
            'お客さまが、株式会社ＣＤエナジーダイレクト（以下「当社」といいます。）にガス使用の申し込みをしていただくにあたり、当社がガス事業法に基づき説明し、お客さまにご確認いただきたい主要な供給条件は以下のとおりです。なお、ガスの供給及び使用に関する契約（以下「ガス需給契約」といいます。）の詳細は、ガス基本契約要綱及びガス個別要綱（以下「要綱等」といいます。）に定めています。当社は、ガス事業法第14条に基づく書面の交付（契約締結前書面）及び同法第15条に基づく書面の交付（契約締結後書面）について、書面でお知らせする事項を除いては、書面交付に代えて、要綱等を当社のホームページに掲載する方法によりこれを提供いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１．ガス使用の申し込み及びガス需給契約の成立',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが新たにガス需給契約を希望される場合は、あらかじめ要綱等を承諾のうえ、当社が必要とする事項を明らかにし、所定の様式により申し込みをしていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)申し込みは、当社所定の場所で受け付けます。なお、当社が適当と判断した場合は、口頭、電話、インターネット等による申込みを受け付けることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)ガス需給契約のお申し込みをされる場合は、お客さまは、あらかじめ、次の事項を承諾するものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ア　一般ガス導管事業者が託送供給約款において定める需要家等に関する事項を遵守すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'イ　当社が法令に基づき実施した消費機器調査の結果等について、一般ガス導管事業者へ調査後遅滞なく提供すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'ウ　法令に定める直近のガス機器調査の結果等、需給契約の締結に必要な事項について、一般ガス導管事業者からガス小売事業者へ提供すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'エ　消費段階における事故が発生した場合には、当社は、一般ガス導管事業者から、一般ガス導管事業者が事故現場で把握した情報の提供を受けること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)ガス需給契約は、お客さまからの申し込みを受け、当社が承諾したときに成立します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)当社は、法令、ガスの製造供給能力、ガス工作物の状況、料金の支払状況（既に消滅しているものを含む他の契約の料金支払状況を含みます。）その他の状況に鑑み、適当でないと判断した場合には、申し込みを承諾しないことがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '２．使用開始予定日',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社へスイッチングされる場合の供給開始予定日は、原則として、従前のガス小売事業者（旧ガス小売事業者）との解約や一般ガス導管事業者との託送供給契約成立等の手続きが完了した後の定例検針日（次回検針日または次々回検針日）の翌日といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)転宅等で新たにガスの使用を開始される場合の供給開始予定日は、お客さまが希望される日を基準として、協議することといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)供給開始後に、ご契約内容をお知らせする書面を送付いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)旧ガス小売事業者への解約連絡は、原則として、当社がお客さまに代わり行いますので、当社の供給開始とともに旧ガス小売事業者との契約は解約されます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)万が一、供給開始予定日より前にスイッチングの申し込みをキャンセルされる場合は、供給開始予定日の３営業日前までに当社へその旨をお申し出いただく必要がございます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '３．料金プラン・割引種別の適用等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)料金プラン・割引種別はお客さまからの申し込みにもとづき適用いたします。その適用条件の対象となる機器の所有状況について、お客さま宅へのご訪問等の機会を通じて確認させていただく場合があります。適用条件を満たさないでガスをご使用の場合、要綱等に定める方法により精算させていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)対象機器の撤去や当社との電気需給契約の解約等で適用条件を満たさなくなった場合は、すみやかにその旨を当社へ連絡いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '４．ガスご使用量の計量やガス料金の算定方法等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)一般ガス導管事業者が託送供給約款に基づき検針を行い、前回の検針日及び今回の検針日におけるガスメーターの読みによりその料金算定期間の使用量を算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)ガスメーターの故障その他の事由により使用量が不明の場合には、託送供給約款に定めるところによりお客さまとの協議により使用量を算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)当社は、その使用量をWEB会員サービス「カテエネ」・「ビジエネ」によりお客さまへお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '（供給開始後に当社より送付するご契約内容をお知らせする書面内に「カテエネ」・「ビジエネ」の登録方法を記載しています。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)当社は、ガス個別要綱の料金表を適用して、その使用量に基づきガス料金を算定いたします。料金算定期間は、次の期間をいいます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ア　検針日の翌日から次の検針日までの期間（イ及びウの場合を除きます）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'イ　新たにガスの使用を開始した場合又はガスの供給を再開した場合、その開始又は再開の日から次の検針日までの期間',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ウ　ガスの供給を停止した日にガスの供給を再開した場合、供給再開日の翌日から次の検針日までの期間',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)新たにガスの使用を開始し、又は解約を行った場合（スイッチングによる解約の場合を除きます）等で料金算定期間が29日以下又は36日以上となったときや、定例検針日の翌日から次の定例検針日までの期間が24日以下又は36日以上となったとき等に、要綱等に定める算定式に基づき、当該料金算定期間の料金を日割計算により算定いたします。ただし、当社の都合で料金算定期間の日数が36日以上となった場合を除きます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)ガス料金は、1ヶ月あたりの基本料金と、1㎥あたりの単位料金にガスご使用量を乗じた従量料金を合計して算定します。割引制度の適用がある場合は、その合計から割引額を差し引いたものを料金といたします。ただし、適用する割引種別により割引額には上限があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(7)単位料金は、都市ガスの原料価格の変動に応じて毎月調整します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '＜計算方法＞',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'ガス料金 = 基本料金(税込) + 従量料金(単位料金（税込）※1 × ガス使用量) + 割引額（税込）((基本料金 + 従量料金) × 割引率)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '※1　原料価格の変動に応じて、毎月調整いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '※2　割引制度の適用がある場合、割引額を差し引きます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(8)ガス料金プランの料金表及び適用条件については、ガス個別要綱等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '５．ガス料金のお支払い',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)ガス料金又は延滞利息は、原則として、口座振替又はクレジットカード払いにより、毎月お支払いいただきます。ただし、供給開始後、お支払方法の手続きが完了するまでにガス料金又は延滞利息をお支払いいただく場合等には、払込みの方法によりお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが、当社と電気需給契約を締結されている場合のガス料金は、原則として、その電気需給契約における電気料金の支払いと同一の方法により、電気料金とあわせてお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)ガス料金の支払義務は、要綱等の定めに基づき、原則として、検針日の属する月の翌月第３営業日に発生し、支払期限日は、支払義務発生日の翌日から起算して30日目といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)支払期限日を経過してもなお料金のお支払いがない場合は、要綱等の定めに基づき延滞利息を申し受けます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '６．供給するガスの熱量、圧力及びガスグループ',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '当社が供給するガスの熱量、圧力及びガスグループは次の通りです。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[熱量]標準熱量45メガジュール、最低熱量44メガジュール',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[圧力]最高圧力2.5キロパスカル、最低圧力1.0キロパスカル',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[ガスグループ]13A',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'ただし、上記の最高圧力を超えるガスの使用のお申し込みがある場合には、お客さま及び一般ガス導管事業者と協議の上、圧力を定めて供給することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '７．お客さまの申し出によるガス需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまのお申し出による契約の変更及び転宅等による解約については、記載のお問い合わせ先までご連絡ください。契約を変更された場合の料金適用開始日は契約変更後の定例検針日の翌日といたします。また、転宅等による解約を希望される場合は、解約を希望される日の３営業日前までに当社へお申し出いただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが当社から他のガス小売事業者へスイッチングされる場合の解約については、原則として、新たなガス小売事業者に対し契約の申し込みをしていただきます。（当社への解約のお申し出は不要です。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '８．当社からの申し出によるガス需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社は、要綱等を変更することがあります。この場合には、原則として、料金にかかわる供給条件は変更の直後の検針日の翌日から、その他の供給条件は変更を行った日から、変更後の要綱等によります。この場合、変更後の要綱等を当社のホームページに掲示する方法又はその他当社が適当と判断した方法により公表いたします。なお、お客さまは、変更を承諾いただけない場合は契約を解約することができます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)要綱等又はガス需給契約の内容を変更する場合は、次項に定める場合を除き、ガス事業法第14条に基づく供給条件の説明及び書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、説明を要する事項のうち当該変更をしようとする事項のみを説明し記載すれば足りるものといたします。また、同法第15条に基づく書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、当社の名称及び住所、契約年月日、当該変更をした事項並びに供給地点特定番号のみを記載すれば足りるものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)要綱等又はガス需給契約の内容について、法令の制定又は改廃に伴い当然必要とされる形式的な変更その他のガス需給契約の実質的な変更を伴わない変更をしようとする場合、ガス事業法第14条に基づく供給条件の説明については、説明を要する事項のうち当該変更をしようとする事項の概要のみを書面交付することなく説明すれば足りるものといたします。また同法第15条に基づく書面の交付については、これを行わないものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまが支払期限日を経過しても料金、延滞利息その他要綱等に基づく債務のお支払いがない場合、当社との他の契約（すでに消滅しているものを含みます。）の料金又は延滞利息についてお支払いがない場合には、当社はガス需給契約を解約することがあります。また、ガスを不正に使用した等、当社が要綱等に定める一定の事由に該当するときは、ガスの供給を停止又は解約することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまが当社にガスの使用廃止の通知をしない場合であっても、すでに転居されている等明らかにガスの使用を廃止したと認められるときは、当社はガスの供給を終了させるための措置を行うことがあります。その場合、この措置をとった日に解約があったものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '９．導管、ガスメーターその他の設備に関する費用負担',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)ガス工事をお申し込みされる場合は、一般ガス導管事業者が定めるガス工事約款に基づき、一般ガス導管事業者に申し込みをしていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)内管、ガス栓、お客さまのために設置されるガス遮断装置、昇圧供給装置及び整圧器はお客さまの所有とし、お客さまの費用負担で設置していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)ガスメーターは一般ガス導管事業者が所有するものを設置し、これに要する設置工事費はお客さまにご負担いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)供給管は一般ガス導管事業者が所有し、これに要する工事費は一般ガス導管事業者が負担いたします。ただし、お客さまの依頼により供給管の位置変え等を行う場合は、これに要する工事費はお客さまにご負担いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)本支管及び整圧器（お客さまのために設置される整圧器は除きます）は、一般ガス導管事業者の所有とし、一般ガス導管事業者のガス工事約款に定める差額が生じた場合は、その差額に消費税等相当額を加えたものを工事負担金としてお客さまにご負担いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)その他設備に関するお客さまの費用負担については、一般ガス導管事業者のガス工事約款の定めに従うものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１０．導管、器具、機械その他の設備に関する保安上の責任',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)内管及びガス栓等、一般ガス導管事業者のガス工事約款の規定によりお客さまの資産となる供給施設については、お客さまの責任において管理していただきます。また、一般ガス導管事業者は、ガス事業法令の定めるところにより、お客さまの資産となる供給施設について検査及び緊急時の応急の措置等の保安責任を負います。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)当社又は一般ガス導管事業者は、お客さまに対し、ガスの使用に伴う危険の発生を防止するため、ガス事業法令の定めるところにより、報道機関、印刷物等を通じて必要な事項をお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)当社は、ガス事業法令の定めるところにより、屋内に設置された不完全燃焼防止装置の付いていないふろがま、湯沸し器等のガス機器について、お客さまの承諾を得て、ガス事業法令で定める技術上の基準に適合しているかどうかを調査します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまは、ガス漏れを感知したときは、直ちにメーターガス栓及びその他のガス栓を閉止して、一般ガス導管事業者に通知していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまは、当社及び一般ガス導管事業者がガスの使用に関してお知らせした事項等を遵守して、ガスを適正かつ安全に使用していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)その他保安について、要綱等の「保安に対するお客さまの協力」、「お客さまの責任」に定められた事項を遵守していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１１．託送供給約款に定められたお客さまの責任に関する事項',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(1)ガスの使用にあたり、託送供給約款に定められる以下の事項について承諾いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ア　必要な業務のために、お客さまの供給施設又は消費機器の設置の場所へ立ち入ること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'イ　ガスの供給及び保安上の必要がある場合に、お客さまのガスの使用を中止又は制限すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ウ　ガス需給契約が解約された後も、ガスメーター等の供給施設を引き続き置かせていただくこと。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)ガス供給に伴い必要なお客さまの協力、保安等や調査に対するお客さまの協力等、託送供給約款に定められるお客さまの協力に関する事項について承諾いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１２．その他',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが当社にスイッチングされると、旧ガス小売事業者との契約は解約となりますので、その契約内容によっては旧ガス小売事業者に対する解約金が発生する場合があります。また、旧ガス小売事業者で利用されているポイント等のサービスが失効・停止する場合等、お客さまの不利益になる事項が発生する場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)クーリング・オフにより契約を解除された場合や当社から契約を解約した場合等で、お客さまが無契約状態となったときには、ガスの供給が停止いたしますので、契約の締結を希望されるガス小売事業者へお申し込みいただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)当社又は一般ガス導管事業者が解約をし、又は供給若しくは使用の制限、中止若しくは停止をしたために、お客さま又は第三者が損害を受けられても、当社の責めに帰すべき事由がないときは、当社は賠償の責任を負いません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまが境界線内の一般ガス導管事業者のガス工作物を故意又は重過失により損傷し又は失わせて、当社又は一般ガス導管事業者に重大な損害を与えた場合、ガスを不正に使用した場合等、当社又は一般ガス導管事業者が損害を受けたときは、その損害を賠償していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)当社は、個人情報の一部を共同利用することがあります。共同利用における利用項目、利用者の範囲、利用目的等の詳細は当社のプライバシーポリシーをご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    service: [
      [
        {
          text: 'スマモル賃貸プランに関する重要事項説明',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１．料金の特徴',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸プランは、「スマートロック」「駆けつけサービス」「優待・割引サービス」が付帯したプランです。各種サービスの利用料金は、お支払いいただく電気料金に含まれます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '２．スマモル賃貸プランのお申込みについて',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸プランのお申込みをご希望の場合、物件管理等を行う事業者（以下、「管理会社等」といいます。）より、お客さまからお知らせいただいたメールアドレス宛または携帯電話番号宛に、当社のお申込みサイトのURLを送付いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●お客さまは、URLの案内に従い、お申込みに必要な情報を入力いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●お申込みいただいた内容は、スマモル賃貸プランの申込みに利用するほか、スマモル賃貸サービスのご利用に必要な範囲で、管理会社等に連携いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●お申込みサイトには、お客さまからあらかじめ管理会社等にお伝えいただいた入居情報が表示されます。表示内容が異なる場合は、お申込み内容の入力前に、必ず管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '３．付帯サービスについて',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●スマモル賃貸プランには、以下のサービスが付帯します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　①スマートロック',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　株式会社ビットキーが提供するスマートロック（お客さまの需要場所の入口等の鍵に取り付けることで、スマートフォン等から扉の鍵を開閉できる商品等であって、当社が指定するもの）をご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　②駆けつけサービス',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　大阪ガスセキュリティサービス株式会社が提供する、暮らしの中のトラブルに対し、警備員による出張対応等を行うサービスをご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　③優待・割引サービス',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　トラブル、グルメ、レジャー、エンターテインメントなどの様々なサービスが市価または定価に比べ割安に利用できるサービスをご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●付帯サービスは、原則として料金適用開始の日からご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸サービスの内容は、それぞれのサービスの要綱または利用規約等にて定めるものといたします。当社以外の事業者がサービス内容または利用規約等を変更したことにより、お客さまに不利益が生じても、当社はその責任を負いません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●お客さまの需要場所等により、一部のサービスを提供できない場合があります。お申込みの前に管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '４．契約期間について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●ご契約期間は、需給契約が成立した日から、料金適用開始の日が属する月をひと月目として24か月目の月の末日といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '５．契約の更新について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●ご契約期間満了１か月前を目途に電磁的方法等によりお知らせいたします。同内容で契約を継続される場合、特段のお手続きは不要です。この場合、１年ごとに同条件で契約が継続されます。また、継続後の期間については、６．で定める解約金は適用されません。契約種別の変更や契約期間満了による終了をご希望の場合は、お送りするお知らせに従って、お手続きください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '６．契約種別の変更・解約について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●契約期間途中に、当社との需給契約を廃止し、他の小売電気事業者との需給契約等にもとづき当該需要場所で引き続き電気を使用される場合、当社による解約または当社の他の契約プランへの変更により、お客さまにスマモル賃貸プランの適用がなくなった場合は、原則として、適用がなくなった日から当該契約が満了する予定であった日までの残存期間（１カ月未満の端数は切り捨てます。）に応じて、１か月あたり1,200円を解約金として最終の電気料金とあわせて当社にお支払いいただきます。ただし、契約期間満了日が属する月の前月１日から契約期間満了日までの期間に、お客さまにスマモル賃貸プランの適用がなくなった場合は、当社は解約金をいただきません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸プランを料金適用開始の日が属する月をひと月目として24か月目の月の末日までに解約した場合、当該契約が満了する予定であった日まではスマモル賃貸サービスを利用いただけます。当該契約が満了する予定であった日以降のスマートロックのご利用には、別途費用が発生する場合があります。詳細は管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '７．お申込みについて',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●当社が適当でないと判断した場合、その他やむを得ない事情がある場合には、申込みを承諾できない場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '８．当社によるプラン変更・廃止について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●スマモル賃貸プランの内容を変更・廃止する場合があります。あらかじめご了承ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '９．その他',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●付帯サービスをお客さまに提供するにあたり、お客さまの名義、需要場所（供給地点特定番号を含みます。）、電話番号、その他の需給契約に係る事項ならびに付帯するサービス契約に係る事項について、株式会社ビットキーおよび大阪ガスセキュリティサービス株式会社に情報を提供することならびに株式会社ビットキーおよび大阪ガスセキュリティサービス株式会社から情報の提供を受けることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●現在ご契約の料金メニューによっては、スマモル賃貸プランへの変更により、デメリットが生じる場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●お引越しされる場合は、当社へ電気の需給契約の廃止のご連絡をしていただく必要がございます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●詳細の運用等については、電気基本契約要綱（低圧）および電気個別要綱（スマモル賃貸プランB）、電気個別要綱（スマモル賃貸プランAE）に定めるとおりといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●その他の事項については、「電気需給契約に関する重要事項説明書」をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    company: [
      [
        {
          text: '株式会社CDエナジーダイレクト',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '(小売電気事業者登録番号 A0490)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '〒103-0022 東京都中央区日本橋室町四丁目5番１号',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: 'お問い合わせ先 0120-811-792',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '受付時間　平日　9時〜19時 ・ 土日、祝日、1/2、1/3 9時〜17時',
          type: ComponentDescriptionType.Normal
        }
      ]
    ]
  };
};
